
import React, { useState, useEffect,useCallback } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { AddComments, AddLike, getOnePost, userProfile, MyNetworks } from "../../../Services/UserAuth";
import Spinner from "../../MyProfile/Spinner";
import { ThreeDots } from "react-loader-spinner";
import Navbar from "../../../Pages/Component/Navbar";
import Hero from "../../../Pages/Component/Home/Hero";
//import UserNavbar from "../../UserNavbar";
import Header from "../../../Component/Header";
import WallPost from "./WallPost";
import { SideBar } from "../../common-component/SideBar";
import useUser from "../../../hooks/useUser";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Dropdown } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { LeatestNews } from "./LeatestNews";

import { ConnectProffestionals } from "../../common-component/ConnectProffestionals";
const API_Base_Url = process.env.REACT_APP_BACKEND_URL
const UserPost = ({ }) => {
    const { user, updateUser } = useUser();
    const { postid } = useParams()
    const location = useLocation();
    const navigate = useNavigate();
    const [likeH, setLikeH] = useState(false)
    const [isLoaded, setIsLoaded] = useState(true);
    const [newComment, setNewComment] = useState(true)
    const [listComments, setListComments] = useState([])
    const userAllData = JSON.parse(localStorage.getItem("userData"));
    const [currentPostid, setCurrentPostId] = useState("")
    const [postdata, setPostData] = useState(null)
    const [likedPosts, setLikedPosts] = useState([])
    const [likesC, setLikesC] = useState(0)
    const [postComment, setPostComment] = useState([])
    const [commentC, setCommentC] = useState(0)
    const shareurl = `${process.env.REACT_APP_REDIRECT_URL}/userpost/` + currentPostid
    //Add remove like from button
    const [likes, setLike] = useState(false);
    const [allUserLists, setAllUserLists] = useState([])
    const [po, setpostid] = useState("")
    const [isCopied, setIsCopied] = useState(false)
    const [filtered, setFiltered] = useState([])
    const [newtworks, setNetworks] = useState([])
    const [body, setBody] = useState("")
    const [mentions, setMentions] = useState([])
    const [commentIndex, setCommentIndex] = useState(0)
    const userProfilePicture = user !== null ? user?.profilePicture : null
    const token = userAllData !== null ? userAllData.token : null
    const linkedinId = user !== null ? user?.linkedinId : null

    const [showEditPostModal, setShowEditPostModal] = useState(false);
    const handleClose = () => setShowCommentEditModel(false);

    const convertUrlsToLinks = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        let finalResult = '';
        text.split(urlRegex).map((part, index) => {
            if (part.match(urlRegex)) {
                finalResult += `<a href="${part.trim().replace(/,$/, '')}" target="_blank">${part}</a>`;
            }
            else {
                finalResult += part
            }
        });
        return finalResult;
    };

    useEffect(() => {
        var templistcomments = listComments;
        templistcomments.push("");
        setListComments(templistcomments)
        LoadMyNetworks();
        LoadPostDetails()
    }, [])

    const ListofLikedUsers = (id) => {
        GetLikesApi(userAllData?.token, id).then((res) => {
            if (res !== undefined) {
                if (res.success === true) {
                    setAllUserLists(res.data)
                    window.$('#modalLikes').modal('show');

                }
            }
        })
    }

    const GetLikesApi = async (token, userid) => {
        try {
            const response = await fetch(
                `${API_Base_Url}/v1/user/posts/${userid}/likes`,
                {
                    method: "get",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        authorization: token,
                        // 'Authorization': 'Bearer ' + this.state.AccessToken
                    }
                }
            );
            if (response.status === 401) {
                toast.error("Your Session has been expired, Please login again.");
                return window.setTimeout(function () {
                    localStorage.clear();
                }, 1000);
            }
            const result = await response.json();
            if (response.ok) {
                return result;
            } else if (response.status === 400) {
                toast.error(result.message);
            } else {
            }
        } catch (error) {
            toast.error("Something went wrong , Please try again later.");
        }
    };

    const LoadPostDetails = () => {
        try {
            setIsLoaded(false)
            if (token !== undefined && token !== null) {
                userProfile(token)
                    .then((res) => {
                        if (res.status === true) {
                            //setUserData(res);
                            /* let userProfileData = JSON.stringify(res.data)
                            localStorage.setItem("userProfileData", userProfileData); */
                            updateUser(res.data)
                        }
                    })
                    .catch();
            }
            if (postid !== null) {
                getOnePost(postid).then((res) => {

                    if (res !== undefined) {
                        if (res.success) {
                            setPostData(res.data)
                            console.log(res.data, "RES DATA")
                            setIsLoaded(true);
                        }
                    }
                });

            }
        } catch (error) { }
    }

    const Like = (postid, total, haslike) => {
        if (!userAllData) {
            return;
        }
        try {

            if (postid !== null) {
                var temp = likedPosts
                if (temp.includes(postid)) {
                    temp = temp.filter(x => x != postid)
                }
                else {
                    temp.push(postid)
                }

                if (haslike) {
                    setLikesC(total - 1)
                    setLikeH(!haslike)
                    setLikedPosts(temp)
                    setPostData({
                        ...postdata,
                        totalLikes: total - 1,
                        hasUserLiked: false
                    });
                }
                else {
                    setLikesC(total + 1)
                    setLikeH(!haslike)
                    setLikedPosts(temp)
                    setPostData({
                        ...postdata,
                        totalLikes: total + 1,
                        hasUserLiked: true
                    });
                }

                if (postid !== null && token !== undefined && token !== null) {
                    AddLike(postid).then((res) => {
                        if (res != undefined) {
                            if (res.success) {
                                setLike(!likes)
                                setpostid(res.data.post)
                                //LoadPostDetails();
                            }

                        }
                    });
                }
                else {
                    toast.warn("Please login or signup.")
                }
            }
        }

        catch (error) { }
    }
    // Adding Comments
    const AddCommentsNow = (postId, parentComment, comment, i, e) => {
        var tempcomment = listComments;
        tempcomment[i] = "";
        setListComments(tempcomment)
        const trimmedComment = comment.trim();
        const data = {
            postId,
            parentComment,
            comment: trimmedComment,
            mentions
        };
        try {
            if (linkedinId !== null && comment !== null && comment !== "") {
                AddComments(data, linkedinId).then((res) => {
                    setNewComment(true)
                    if (res != undefined) {
                        if (res.success) {
                            var elements = document.getElementsByClassName("textareaInput");
                            for (var i = 0, length = elements.length; i < length; i++) {
                                elements[i].value = '';
                            }
                            setPostData(res?.data);
                            setBody("");
                        }
                    }
                });
                //set comment text box empty
                e.target.value = "";
            } else if (comment === null || comment === "") {
                toast.error("Please Enter Comment")
            }
        } catch (error) { }
    }

    const handleKeyEnter = (e, id, pc, i) => {
        if (e.key === 'Enter') {
            AddCommentsNow(id, pc, e.target.value, i, e)
        }
    }

    const LoadMyNetworks = () => {
        try {
            if (userAllData.token !== undefined && userAllData.token !== null) {
                MyNetworks(userAllData.token, user?._id || userAllData?.data?._id)
                    .then((res) => {
                        if (res.success) {
                            setNetworks(res.data);
                        }
                    })
                    .catch();
            }
        } catch (error) { }
    }

    // handle change comment which stores comment and display users to mention
    const handleComment = (e, i) => {
        var value = e.target.value;
        setCurrentPostId(e.target.name)
        if (value.includes("@") && !value.includes("@ ")) {
            var temp = value.split("@")
            if (temp[temp.length - 1] !== "") {
                var filtered = newtworks.filter((item) => {
                    return (item.firstName.toLowerCase().includes(temp[temp.length - 1].toLowerCase() || item.lastName.toLowerCase().includes(temp[temp.length - 1].toLowerCase())))
                })
                setFiltered(filtered)
            }
            else
                setFiltered(newtworks)
        }
        else
            setFiltered([])

        setBody(value);
        var tempcomment = listComments;
        tempcomment[i] = e.target.value;
        setListComments(tempcomment)
    }

    // adding mentioned persons id in mentions array and modifying body of comment
    const handleMentions = (val) => {
        var temp = mentions
        console.log("val", val)
        temp.push(val._id)
        setMentions(temp);
        var b = body.split("@")
        setBody(body.replace(b[b.length - 1], '') + val.firstName + " " + val.lastName);
        var tempcomment = listComments;
        tempcomment[commentIndex] = body.replace(b[b.length - 1], '') + val.firstName + " " + val.lastName;
        setListComments(tempcomment)
        setFiltered([])
    }

    const CopyShareLink = (e) => {
        navigator.clipboard.writeText(shareurl)
    }

    const gotoMentorProfile = (id) => {
        const _id = id;
        if (_id === user?._id) {
            navigate('/myprofile')
        }
        else {
            //localStorage.setItem('Mentor_id', _id)
            navigate('/userprofile/' + _id)
        }
    }

    //Edit Code for comments

    const [commentId, setCommentId] = useState("");
    const [commentMessage, setCommentMessage] = useState("");
    const handleShow = () => setShowCommentEditModel(true);
    const [showCommentEditModel, setShowCommentEditModel] = useState(false);

    const handleEditComment = async (val, postId) => {
        setCommentId(val._id);
        setCurrentPostId(postId); // post id 
        handleShow();

        if (val.comment) {
            {
                const formattedComment = val.comment.replace(/<[^>]+>/g, "");
                setCommentMessage(formattedComment);
            }
            /// mentions is in array so we need to set it in array and only id  tobe set in mentions
            if (val.mentions !== null && val.mentions.length > 0) {
                let temp = [];
                val.mentions.map((item) => {
                    temp.push(item._id);
                });
                setMentions(temp); // contains id of mentions
            }
        }
    };

    // delete comment of post api call
    const handleDeleteComment = async (id, postId) => {
        const response = await fetch(
            API_Base_Url + "/v1/user/delete-comment/" + id,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    authorization: userAllData?.token,
                },
            }
        );
        const data = await response.json();
        if (data.success) {
            toast.success("Comment Deleted Successfully");
            //loadAllPosts();
            const updatedComments = postdata?.comments?.filter(comment => comment._id != id);
            setPostData({
                ...postdata,
                comments: updatedComments,
                totalComments: postdata.totalComments - 1
            });
        }
    };


    const onSubmitEditComment = async () => {
        if (!commentMessage || !commentMessage.trim()) {
            toast.error("Please Enter Comment");
            setBody("");
            return;
        }

        const BodyData = {
            comment: commentMessage,
            mentions: mentions,
            postId: currentPostid
        };
        // console.log("BodyDataedit", BodyData);
        try {
            const response = await fetch(
                `${API_Base_Url}/v1/user/edit-comment/${commentId}`,
                {
                    method: "PUT",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: userAllData?.token,
                    },
                    body: JSON.stringify(BodyData),
                }
            );

            const data = await response.json();

            if (data.success) {

                toast.success("Comment Edited Successfully");
                //UpdateAllUserPosts(currentPostid, data?.data);
                setPostData(data?.data);
                handleClose();
                // loadAllPosts();
            } else {
                toast.error("Failed to edit comment");
            }
        } catch (error) {
            console.error("Error editing comment:", error);
            toast.error("Something went wroong");
            handleClose();
            // Handle error (e.g., show an error toast)
        }
    };

    /// i also want to add mentions in edit comments 
    const handleEditCommentChange = (e) => {
        var value = e.target.value;
        if (value.includes("@") && !value.includes("@ ")) {
            var temp = value.split("@");
            if (temp[temp.length - 1] !== "") {
                var filtered = newtworks.filter((item) => {
                    return item.firstName
                        .toLowerCase()
                        .includes(
                            temp[temp.length - 1].toLowerCase() ||
                            item.lastName
                                .toLowerCase()
                                .includes(temp[temp.length - 1].toLowerCase())
                        );
                });
                setFiltered(filtered);
            } else setFiltered(newtworks);
        } else setFiltered([]);

        setCommentMessage(value);


    };

    const handleMentionsEditComment = (val) => {
        //console.log("val in mentions mm", val);
        let temp = mentions;
        // console.log("mentions edit", val._id);
        temp.push(val._id);
        setMentions(temp);
        // console.log("etemp", temp);
        let b = commentMessage.split("@");
        setCommentMessage(
            commentMessage.replace(b[b.length - 1], "") +
            val.firstName +
            " " +
            val.lastName
        );

        let tempComment = listComments;

        tempComment[commentIndex] =
            commentMessage.replace(b[b.length - 1], "") +
            val.firstName +
            " " +
            val.lastName;

        // console.log("etempComment", tempComment);
        setListComments(tempComment);
        setFiltered([]);
    };


/* connect with linked in Account */

    const [iscode, setIscode] = useState(false);
    useEffect(() => {
        const params1 = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        }, []);
        let value = params1.code;

        if (value !== null) {
            setIscode(true);
            const code = value;
            //console.log(code)
            loadData(code)
        }
        //handleClick();
    }, []);

    const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
    const redirect_url = process.env.REACT_APP_REDIRECT_URL;

    const handleClick = useCallback(() => {
        // Get redirectUrl from localStorage
        const redirectUrl = localStorage.getItem("redirectUrl");
        // Clear local storage
        localStorage.clear();
        // Set redirectUrl in localStorage
        localStorage.setItem("redirectUrl", redirectUrl);
        const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${id}&redirect_uri=${redirect_url}&state=123456&scope=openid,profile,email`;
        window.location.href = url;
    }, [id, redirect_url]);

    const loadData = async (code) => {
        //console.log("redirectURL", redirectUrl)
        try {

            const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
            const redirect_url = process.env.REACT_APP_REDIRECT_URL;
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
                {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",

                        //   'Authorization': 'Bearer ' + this.state.AccessToken
                    }
                }
            );
            const res = await response.json();
            const authorize = res
            let issuccess = res.success
            let verified = false

            if (!issuccess) {
                console.log(res.message);
                toast.error(res.message)
                setTimeout(() => {
                    window.location.href = "/";
                }, 1500);

            }
            else {
                issuccess = true;
                verified = res.data.data.isVerified
            }

            if (verified) {
                let allData = JSON.stringify(authorize.data);
                localStorage.setItem("userData", allData);
                const isSessionpage = localStorage.getItem("isSessionpage")
                if (!authorize.data.data.hasUserAddedSkills) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/mentee-topics");
                }
                else if (isSessionpage === "yes") {
                    window.location.replace(`${redirect_url}/session`)
                    localStorage.removeItem("isSessionpage")
                }
                else {
                    //get redirectUrl from localStorage
                    const redirectUrl = localStorage.getItem("redirectUrl");
                    if (!redirectUrl || redirectUrl === "null") {
                        window.location.replace(`${redirect_url}/home-page`)

                    }
                    else {
                        //navigate to redirectUrl
                        window.location.href = redirectUrl;
                    }
                }




            }
            else if (issuccess) {
                if (authorize.data.data.completedStep == 1) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    window.location.replace(`${redirect_url}/email`)
                    //navigate("/email");
                } else if (authorize.data.data.completedStep == 2) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/address");
                } else if (authorize.data.data.completedStep == 3) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/employement");
                }
                else if (authorize.data.data.completedStep > 3 && !authorize.data.data.hasUserAddedSkills) {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/mentee-topics");
                }
                else {
                    let allData = JSON.stringify(authorize.data);
                    localStorage.setItem("userData", allData);
                    navigate("/email");
                }


            }
            else {
                navigate("/");
            }
        }
        catch (e) {
            console.log(e)
        }

    }


    return (
        isLoaded ?
            <>
                {(postdata !== null) &&
                    <>
                        <section>
                            {/* (userData !== null && userData !== undefined) */ (user !== null && user !== undefined) ?
                                <Header />
                                :
                                <Navbar handleClick={handleClick} />
                            }
                            <main className="mt-2 pt-5">
                                {/* <!-- Container START --> */}
                                <div className="container">
                                    <div className="row g-4">
                                        {/* <!-- Sidenav START --> */}
                                        {
                                            (user !== null && user !== undefined) ? <SideBar /> : <div class="col-lg-3"></div>
                                        }
                                        {/* <!-- Sidenav END --> */}

                                        {/* <!-- Middle  content START --> */}
                                        <div className="col-lg-6">
                                            <div className="row">
                                                <div className="card px-0">
                                                    {/* <!-- Card header START --> */}
                                                    <div className="card-header border-0 pb-0">

                                                        <div className="d-flex align-items-center justify-content-between">

                                                            <div className="d-flex align-items-center">
                                                                {/* <!-- Avatar --> */}
                                                                <div className="avatar avatar-story me-2">

                                                                    <a>

                                                                        {(postdata.posted_by != null && postdata.posted_by.length > 0) &&
                                                                            <LazyLoadImage src={postdata.posted_by[0]?.profilePicture}
                                                                                alt="" className="avatar-img rounded-circle" crossorigin="anonymous"/>
                                                                        }
                                                                    </a>


                                                                </div>
                                                                {/* <!-- Info --> */}
                                                                {(postdata.posted_by !== null && postdata.posted_by !== undefined) &&
                                                                    <div>
                                                                        <div className="nav nav-divider">
                                                                            <h6 className="nav-item card-title mb-0">
                                                                                {" "}
                                                                                <a target="_blank" onClick={(e) => gotoMentorProfile(postdata.posted_by[0]?._id)} style={{ cursor: "pointer" }}>
                                                                                    {postdata.posted_by[0]?.firstName} {postdata.posted_by[0]?.lastName}
                                                                                </a>
                                                                            </h6>
                                                                            <span className="nav-item small">{moment.utc(postdata.createdAt).local().startOf('seconds').fromNow()}</span>
                                                                        </div>
                                                                        <p className="mb-0 small">{postdata.posted_by[0]?.currentJobTitle}</p>
                                                                    </div>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* <!-- Card header END --> */}
                                                    {/* <!-- Card body START --> */}
                                                    <div className="card-body">
                                                        {(postdata.mentions !== null && postdata.mentions.length > 0) ?
                                                            <p>{
                                                                postdata.mentions.map((item, i) => {
                                                                    var a = postdata.body
                                                                    postdata.body = a.replace(("@" + item.firstName + " " + item.lastName), `<a class=text-purple href="/userprofile/${item._id}">@${item.firstName} ${item.lastName}</a>`)
                                                                    if (i === postdata.mentions.length - 1)
                                                                        return <div dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(postdata.body) }} />
                                                                }
                                                                )
                                                            }
                                                            </p>
                                                            :
                                                            <div dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(postdata.body) }} />
                                                        }
                                                        {
                                                            postdata.urlExtractedBody &&
                                                            <WallPost extractedUrlData={JSON.parse(postdata.urlExtractedBody)} />
                                                        }
                                                        {/* <!-- Card img --> */}
                                                        {
                                                            (postdata.photo !== null && postdata.photo.length > 0) ?
                                                                /*  <img className="card-img" src={postdata.photo[0]} alt="Post" /> */
                                                                <LazyLoadImage className="card-img" src={postdata.photo[0]} alt="Post" crossorigin="anonymous" /> : ""}

                                                        {(postdata.video !== null && postdata.video.length > 0) ?
                                                            <video src={postdata.video} controls /> : ""
                                                        }


                                                        {/* <!-- Feed react START --> */}
                                                        {postdata?.totalLikes > 0 &&
                                                            <ul className="nav nav-pills  nav-fill nav-stack small py-1 my-0">
                                                                <li className="nav-item">

                                                                    <a className="nav-link vtab-top">
                                                                        { }
                                                                        {/* ({likedPosts.includes(val._id) ? likesC : val.totalLikes}) */}
                                                                        {postdata.totalLikes
                                                                            ? <>
                                                                                <i class="text-purple fa-solid fa-heart">
                                                                                </i>
                                                                                <span className="text-purple" onClick={(e) => ListofLikedUsers(postdata._id)}>({likedPosts.includes(postdata._id) ? likesC : postdata.totalLikes})</span>
                                                                            </> : ""}
                                                                    </a>


                                                                </li>


                                                                {/* <!-- Card share action menu START --> */}

                                                            </ul>
                                                        }

                                                        <ul className="nav nav-pills nav-pills-light nav-fill nav-stack small border-top border-bottom py-1 my-3">
                                                            <li className="nav-item">

                                                                <a className={(likedPosts.includes(postdata._id) ? likeH : postdata.hasUserLiked) ? "nav-link mb-0 nav__hover__effect active" : "nav-link mb-0 nav__hover__effect"}>

                                                                    <span onClick={(e) => Like(postdata._id, postdata.totalLikes, postdata.hasUserLiked)}><i className="bi bi-heart pe-2"></i>Like </span>


                                                                    {/* <i class="bi bi-heart-fill"></i>Liked(56) */}
                                                                </a>


                                                            </li>

                                                            <li className="nav-item">
                                                                <Link className="nav-link mb-0 nav__hover__effect" to="#!">
                                                                    {" "}
                                                                    <i className="bi bi-chat-fill pe-1"></i>Comments ({postComment.includes(postdata._id) ? commentC : postdata.totalComments})
                                                                </Link>
                                                            </li>
                                                            {/* <!-- Card share action menu START --> */}
                                                            <li className="nav-item dropdown">
                                                                <Link
                                                                    to="#"
                                                                    className="nav-link mb-0 nav__hover__effect"
                                                                    aria-expanded="false"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modalCreatePrice"
                                                                    onClick={e => setCurrentPostId(postdata._id)}
                                                                >
                                                                    <i className="bi bi-reply-fill flip-horizontal ps-1"></i>
                                                                    Share
                                                                </Link>
                                                                {/* <!-- Card share action dropdown menu --> */}
                                                                <ul
                                                                    className="dropdown-menu dropdown-menu-end"
                                                                    aria-labelledby="cardShareAction"
                                                                >
                                                                    <li>
                                                                        <Link className="dropdown-item" to="#">
                                                                            {" "}
                                                                            <i className="bi bi-envelope fa-fw pe-2"></i>Send via
                                                                            Direct Message
                                                                        </Link>
                                                                    </li>

                                                                </ul>
                                                            </li>
                                                        </ul>
                                                        {/* <!-- Feed react START --> */}

                                                        {/* <!-- Add comment --> */}
                                                        <div className="d-flex mb-3">
                                                            {/* <!-- Avatar --> */}
                                                            {(userProfilePicture !== null && userProfilePicture !== undefined && userProfilePicture !== "") &&
                                                                <div className="avatar avatar-xs me-2">
                                                                    <Link to="#!">
                                                                        {" "}
                                                                       {/*  <img
                                                                            className="avatar-img rounded-circle"
                                                                            src={userProfilePicture}
                                                                            alt=""
                                                                        /> */}
                                                                        <LazyLoadImage
                                                                            src={userProfilePicture || process.env.REACT_APP_NO_IMAGE_URL}
                                                                            alt="" className="avatar-img rounded-circle" crossorigin="anonymous" />
                                                                        {" "}
                                                                    </Link>
                                                                </div>}
                                                            {/* <!-- Comment box  --> */}
                                                            {(linkedinId !== null) &&
                                                                <form className="position-relative w-100" id={postdata._id} >
                                                                    <textarea
                                                                        className="form-control pe-4 bg-light textareaInput"
                                                                        data-autoresize
                                                                        rows="1"
                                                                        placeholder="Add a comment..."
                                                                        name={postdata._id}
                                                                        value={currentPostid === postdata._id ? body : ""}
                                                                        // postdataue={listComments[ind]}
                                                                        onChange={(e) => handleComment(e, 0)}
                                                                        onKeyDown={(e) => handleKeyEnter(e, postdata._id, postdata?.parentComment, 0)}
                                                                    ></textarea>
                                                                    {/* <!-- Emoji button --> */}
                                                                    <div className="position-absolute top-0 end-0"
                                                                        onClick={(e) => AddCommentsNow(postdata._id, null, listComments[0], 0, e)}>
                                                                        <button className="btn" type="button">
                                                                            <i class="fa-solid fa-arrow-right"></i>
                                                                        </button>
                                                                    </div>
                                                                </form>
                                                            }
                                                        </div>

                                                        <div id={postdata._id} className="d-block searchlist" style={{ border: "none", padding: "0px", maxHeight: "100px", marginTop: "0px" }}>
                                                            {filtered.map((val, key) => {
                                                                return (
                                                                    <>
                                                                        <div id={val._id} style={{ border: "1px solid #e7e7e7", padding: "3px", marginBottom: "1px" }}>
                                                                            {/*  <img src={val.profilePicture}
                                                                                style={{ width: "30px", height: "30px", borderRadius: "50%" }} /> */}

                                                                            <LazyLoadImage src={val.profilePicture}
                                                                                style={{ width: "30px", height: "30px", borderRadius: "50%" }} crossorigin="anonymous" />

                                                                            <span
                                                                                key={key}
                                                                                style={{ cursor: "pointer", color: "black" }}
                                                                                onClick={(e) => handleMentions(val)}
                                                                            >
                                                                                {val.firstName + " " + val.lastName}
                                                                            </span>
                                                                            <br></br>
                                                                        </div>

                                                                    </>
                                                                );
                                                            })}
                                                        </div>
                                                        {/* <!-- Comment wrap START --> */}
                                                        {
                                                            (postdata.comments && postdata.comments.length > 0) ?
                                                                postdata.comments.map(item =>
                                                                    <ul className="comment-wrap list-unstyled">
                                                                        {/* <!-- Comment item START --> */}
                                                                        <li className="comment-item">
                                                                            <div className="d-flex position-relative">
                                                                                <div className="comment-line-inner"></div>
                                                                            </div>
                                                                            {/* <!-- Comment item nested START --> */}
                                                                            <ul className="comment-item-nested list-unstyled">
                                                                                {/* <!-- Comment item START --> */}



                                                                                <li className="comment-item"  >
                                                                                    <div className="comment-line-inner"></div>
                                                                                    <div className="d-flex">
                                                                                        {/* <!-- Avatar --> */}
                                                                                        <div className="avatar avatar-xs">

                                                                                            <a>
                                                                                                {
                                                                                                    (item.user.profilePicture != null) &&
                                                                                                    <LazyLoadImage src={item.user.profilePicture || process.env.REACT_APP_NO_IMAGE_URL}
                                                                                                        alt="" className="avatar-img rounded-circle" crossorigin="anonymous" />

                                                                                                }
                                                                                            </a>

                                                                                        </div>
                                                                                        {/* <!-- Comment by --> */}
                                                                                        <div className="ms-2">
                                                                                            <div className="bg-light p-2 rounded">
                                                                                                <div className="d-flex align-items-center justify-content-between">
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <h6 className="mb-1">
                                                                                                            {
                                                                                                                (item.user.firstName != null) ?
                                                                                                                    <a > {item.user.firstName} <span>{item.user.lastName}</span>  </a> : ""
                                                                                                            }
                                                                                                        </h6>
                                                                                                        {/* <small>&nbsp;2hr</small> */}
                                                                                                    </div>
                                                                                                    {userAllData?.data?._id ==
                                                                                                        item.user._id ? (
                                                                                                        <div className="align-items-end">
                                                                                                            <Dropdown>
                                                                                                                <Dropdown.Toggle
                                                                                                                    variant="link"
                                                                                                                    id="options-dropdown"
                                                                                                                    className="displayNone"
                                                                                                                >
                                                                                                                    <span className="fas fa-ellipsis-v text-black"></span>{" "}
                                                                                                                    {/* Font Awesome ellipsis icon */}
                                                                                                                </Dropdown.Toggle>

                                                                                                                <Dropdown.Menu>
                                                                                                                    <Dropdown.Item
                                                                                                                        onClick={(e) =>
                                                                                                                            handleEditComment(
                                                                                                                                item, postdata?._id // postId
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        Edit
                                                                                                                    </Dropdown.Item>
                                                                                                                    <Dropdown.Item
                                                                                                                        onClick={(e) =>
                                                                                                                            handleDeleteComment(
                                                                                                                                item._id, postdata?._id // postId
                                                                                                                            )
                                                                                                                        }
                                                                                                                    >
                                                                                                                        Delete
                                                                                                                    </Dropdown.Item>
                                                                                                                    {/* Add more options as needed */}
                                                                                                                </Dropdown.Menu>
                                                                                                            </Dropdown>
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        ""
                                                                                                    )}
                                                                                                </div>

                                                                                                {<>
                                                                                                    {(item.mentions && item.mentions.length > 0) ?
                                                                                                        <p className="m-0 p-0">{
                                                                                                            item.mentions.map((itemComm, i) => {
                                                                                                                var a = item.comment
                                                                                                                item.comment = a.replace(("@" + itemComm.firstName + " " + itemComm.lastName), `<a class=text-purple href="/userprofile/${itemComm._id}">@${itemComm.firstName} ${itemComm.lastName}</a>`)
                                                                                                                if (i === item.mentions.length - 1)
                                                                                                                    return <div dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(item.comment) }} />
                                                                                                            }
                                                                                                            )
                                                                                                        }
                                                                                                        </p>
                                                                                                        :
                                                                                                        <div className="m-0 p-0" dangerouslySetInnerHTML={{ __html: convertUrlsToLinks(item.comment) }} />
                                                                                                    }
                                                                                                </>

                                                                                                }
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                </li>




                                                                                {/* <!-- Comment item END --> */}


                                                                                {/* <!-- Comment item END --> */}
                                                                            </ul>

                                                                            {/* <!-- Comment item nested END --> */}
                                                                        </li>
                                                                        {/* <!-- Comment item END --> */}
                                                                    </ul>
                                                                )
                                                                : ""
                                                        }
                                                        {/* <!-- Comment wrap END --> */}
                                                    </div>
                                                    {/* <!-- Card body END --> */}
                                                    {/* <!-- Card footer START --> */}

                                                    {/* <!-- Card footer END --> */}

                                                </div>
                                            </div>
                                        </div>
                                        {user !== null && user !== undefined ? (
                    <div className="col-lg-3">
                      <LeatestNews />
                      <ConnectProffestionals type="mentee" />
                      </div>
                  ) : (
                    <div class="col-lg-3"></div>
                  )}

                                    </div>
                                </div>
                            </main>
                        </section>



                    </>}
                {/* Share post modal       */}
                <div class="modal fade" id="modalCreatePrice" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            {/* <!-- Modal header --> */}
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalCreateTime">Share</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body content1">
                                {/* <!-- Form START --> */}
                                <div class="field">
                                    <i class="url-icon uil uil-link"></i>
                                    <input type="text" readonly value={shareurl} />
                                    <button onClick={(e) => { CopyShareLink(e); setIsCopied(true) }}>{isCopied ? "Copied" : "Copy"}</button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                {/* liked by users modal  */}
                <div class="modal fade" id="modalLikes" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            {/* <!-- Modal header --> */}
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalCreateTime">Liked By</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                {(allUserLists !== null && allUserLists.length > 0) &&
                                    allUserLists.map((val, ind) => {
                                        return (
                                            <div className="card mt-3 px-0 pb-3" key={ind}>
                                                {/* <!-- Card header START --> */}
                                                <div className="card-header border-0 pb-0">

                                                    <div className="d-flex align-items-center justify-content-between">

                                                        <div className="d-flex align-items-center">
                                                            {/* <!-- Avatar --> */}
                                                            <div className="avatar avatar-story me-2">

                                                                <a>
                                                                    <img
                                                                        className="avatar-img rounded-circle"
                                                                        src={val.profilePicture}
                                                                        alt=""
                                                                    />

                                                                    <LazyLoadImage src={val.profilePicture || process.env.REACT_APP_NO_IMAGE_URL}
                                                                        alt="" className="avatar-img rounded-circle" crossorigin="anonymous" />
                                                                </a>

                                                            </div>

                                                            <div>
                                                                <div className="nav nav-divider">
                                                                    <h6 className="nav-item card-title mb-0">
                                                                        {" "}

                                                                        <a target="_blank" onClick={(e) => gotoMentorProfile(val._id)} style={{ cursor: "pointer" }}>

                                                                            {val.firstName}{" "}
                                                                            <span>{val.lastName} </span>
                                                                        </a>

                                                                    </h6>
                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        );
                                    })}

                            </div>

                        </div>
                    </div>
                </div>

                {/* ///////========= comment edit modal ============//// */}

                <Modal
                    show={showCommentEditModel}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Comment</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="w-100">
                            <textarea
                                className="form-control pe-2 fs-6 lh-1 border-0"
                                rows="4"
                                autoFocus
                                value={commentMessage}
                                onChange={(e) => handleEditCommentChange(e)}
                            ></textarea>
                        </form>

                        {filtered.length > 0 ? (
                            <div id="search-box" className="d-block searchlist">
                                {filtered &&
                                    filtered.length > 0 &&
                                    filtered.map((val, key) => (
                                        <div key={key} style={{ border: "1px solid #e7e7e7", padding: "3px", marginBottom: "1px" }}>
                                            {/*  <img src={val.profilePicture} style={{ width: "30px", height: "30px", borderRadius: "50%" }} /> */}
                                            <LazyLoadImage src={val.profilePicture} alt="" style={{ width: "30px", height: "30px", borderRadius: "50%"  }}  crossorigin="anonymous" />
                                            <span
                                                style={{ cursor: "pointer", color: "black" }}
                                                onClick={() => handleMentionsEditComment(val)}
                                            >
                                                {val.firstName + " " + val.lastName}
                                            </span>
                                            <br />
                                        </div>
                                    ))}
                            </div>
                        ) : (
                            ""
                        )}


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={onSubmitEditComment}>
                            Update
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
            : <div class="defaultloader">
                <ThreeDots
                    height="80"
                    width="80"
                    radius="9"
                    color="#92278f"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                /></div>
    );

};

export default UserPost;
