import { React, useEffect, useState, useCallback } from "react";
import articleDetails from "./Articledetails";
import CommenHero from "../Component/HeroHeading/CommenHero";
import HeroDetails from "../Component/HeroHeading/HeroDetails";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import { useParams, Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ThreeDots } from 'react-loader-spinner'

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// console.log("first")
const ArticleList = () => {
  // console.log(first)
  const [articleData, setArticleData] = useState([]);
  const [topicName, setTopicName] = useState("");
  const { id } = useParams();
  const getArticleList = async () => {
    const data = await axios.get(
      `${BASE_URL}/v1/Topics/getSigleTopicArticles/${id}`
    );
    setArticleData(data.data.data);

    const dataId = await axios.get(
      `${BASE_URL}/v1/Topics/find-single-data/${id}`
    );
    setTopicName(dataId.data.data.topicName);
  };

  let headings = {
    n1: "Article",
    n2: "Li",
    n3: "sts",
  };

  useEffect(() => {
    getArticleList();
  }, []);

  const navigate = useNavigate();
  const [iscode, setIscode] = useState(false);
  useEffect(() => {
    const params1 = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }, []);
    let value = params1.code;

    if (value !== null) {
      setIscode(true);
      const code = value;
      //console.log(code)
      loadData(code)
    }
    //handleClick();
  }, []);

  const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirect_url = process.env.REACT_APP_REDIRECT_URL;

  const handleClick = useCallback(() => {
    // Get redirectUrl from localStorage
    const redirectUrl = localStorage.getItem("redirectUrl");
    // Clear local storage
    localStorage.clear();
    // Set redirectUrl in localStorage
    localStorage.setItem("redirectUrl", redirectUrl);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirect_url}&state=123456&scope=openid,profile,email`;
    window.location.href = url;
  }, [clientId, redirect_url]);

  const loadData = async (code) => {
    //console.log("redirectURL", redirectUrl)
    try {

      const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
      const redirect_url = process.env.REACT_APP_REDIRECT_URL;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",

            //   'Authorization': 'Bearer ' + this.state.AccessToken
          }
        }
      );
      const res = await response.json();
      const authorize = res
      let issuccess = res.success
      let verified = false

      if (!issuccess) {
        console.log(res.message);
        toast.error(res.message)
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);

      }
      else {
        issuccess = true;
        verified = res.data.data.isVerified
      }

      if (verified) {
        let allData = JSON.stringify(authorize.data);
        localStorage.setItem("userData", allData);
        const isSessionpage = localStorage.getItem("isSessionpage")
        if (!authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else if (isSessionpage === "yes") {
          window.location.replace(`${redirect_url}/session`)
          localStorage.removeItem("isSessionpage")
        }
        else {
          //get redirectUrl from localStorage
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (!redirectUrl || redirectUrl === "null") {
            window.location.replace(`${redirect_url}/home-page`)

          }
          else {
            //navigate to redirectUrl
            window.location.href = redirectUrl;
          }
        }




      }
      else if (issuccess) {
        if (authorize.data.data.completedStep == 1) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          window.location.replace(`${redirect_url}/email`)
          //navigate("/email");
        } else if (authorize.data.data.completedStep == 2) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/address");
        } else if (authorize.data.data.completedStep == 3) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/employement");
        }
        else if (authorize.data.data.completedStep > 3 && !authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/email");
        }


      }
      else {
        navigate("/");
      }
    }
    catch (e) {
      console.log(e)
    }

  }

  return (


    <>
      {
        iscode ?
          <div class="defaultloader">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#92278f"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            /></div>
          :
          <>
            <Navbar handleClick={handleClick} />
            <CommenHero
              allName={headings}
              name1="Article"
              name2="Article List"
              Name3="Article List"
            />
            <div className="privacy_padd">
              <div>
                <h2 className="heading2 mb-0">Tagged in "{topicName}"</h2>
                <h6 className="mt-3 text-black heading6">
                  {articleData.length} article(s)
                </h6>
              </div>
              {articleData.map((val) => {
                return (
                  <div className="mt-5" key={val._id}>
                    <Link to={`/articledetails/${val._id}`} className="ancor_color">
                      <h4 className="heading4 mb-1">
                        {" "}
                        <span>{val.titleName}</span>
                      </h4>
                    </Link>
                    {/* <p className="mb-3 main_para">
                {val.tags.map((e) => e).join(" , ")}
              </p> */}
                    <pre style={{ whiteSpace: "break-spaces" }} className="artice_para">{val.short_Description}</pre>
                  </div>
                );
              })}
            </div>
            <Footer />
          </>

      }


    </>


  );
};

export default ArticleList;
