import io from "socket.io-client";
import axios from "axios";
const url = process.env.REACT_APP_BACKEND_URL;
const socketUrl = url;
//const socketUrl = "https://api.talentizo.ai";
export const instance = axios.create({
  baseURL: url,
});
export default io(socketUrl, {
  secure: false,  
});
