import React, { useEffect, useState, useCallback } from "react";
//import UserNavbar from "../../../../userInterface/UserNavbar";
import GroupDetailHero from "./GroupDetailHero";
import SessionEnrollModal from "./SessionEnrollModal";
import Footer from "../../../Component/Footer";
import { useLocation, useParams } from "react-router-dom";
import Loader from "../../../../Component/Loader";
import moment from "moment";
import "moment-timezone";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getSessionById,
  sessionEnrollmentCheck,
} from "../../../../Services/GroupSessionApiCall";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useUser from "../../../../hooks/useUser";
import { Modal } from "react-bootstrap";
import Header from "../../../../Component/Header";
import Navbar from "../../../Component/Navbar";
import ShareGroupSessionModal from "./ShareGroupSessionModal";
import SEO from "./seo";
// import Helmet from "react-helmet";
import { Helmet } from "react-helmet-async";
import MetaTags from "react-meta-tags";

const GroupSessionDetails = () => {
  const navigate = useNavigate();

  const API_Base_Url = process.env.REACT_APP_BACKEND_URL;
  const { sessionId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isEnrollModalOpen, setIsEnrollModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [sessionData, setSessionData] = useState({});
  const [montorName, setMentorName] = useState("");
  // const { user } = useUser();
  let userBookingData = JSON.parse(localStorage.getItem("userBookingData"));
  let userData = JSON.parse(localStorage.getItem("userData"));
  const user = userData?.data;
  useEffect(() => {
    if (sessionId) {
      fetchSessionById(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionData) {
      setIsLoading(false);
    }
  }, [sessionData]);

  const FreeBooking = async () => {
    const meetingData = {
      sessionId: sessionData?.sessionDetail?._id,
      userId: user?._id,
      amount: "0",
      transactionId: "free",
      currency: "USD",
      sessionType: "GroupSession",
      mentees: [
        {
          _id: user?._id,
          email: user?.email,
          firstName: user?.firstName,
          lastName: user?.lastName,
        },
      ],
      date: sessionData?.sessionDetail?.sessionDate,
      time: moment
        .utc(sessionData?.sessionDetail?.sessionDate)
        .format("hh:mm A"),
      duration: sessionData?.sessionDetail?.sessionDuration,
      timeZone: sessionData?.sessionDetail?.timeZone,
      skill: sessionData?.topicName,
      mentor: {
        _id: sessionData?.mentorId,
        email: userBookingData?.email,
        fullName: montorName,
      },
    };

    navigate("/enrollment-confirmed", {
      state: { meetingData: meetingData },
    });
  };

  const handleOnclickEnroll = async () => {
    if (sessionData?.sessionDetail?.amountPerSeatDollar === 0) {
      try {
        const response = await sessionEnrollmentCheck(
          sessionData?.sessionDetail?._id,
          user?._id
        );
        if (response.status === 200) {
          FreeBooking();
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      setIsEnrollModalOpen(true);
    }
  };
  const formattedFromTime = moment
    .utc(sessionData?.sessionDetail?.sessionDate)
    .format("hh:mm A");
  const formattedToTime = moment
    .utc(sessionData?.sessionDetail?.sessionDate)
    .add(sessionData?.sessionDetail?.sessionDuration, "minutes")
    .format("hh:mm A");

  const handleOpenCOnfirmModal = (sessionData) => {
    if (userData !== null && userData !== undefined) {
      setMentorName(sessionData?.mentorName);
      setIsConfirmModalOpen(true);
    } else {
      handleClick();
    }
  };

  const handleEnroll = async () => {
    handleOnclickEnroll();
    setIsConfirmModalOpen(false);
  };

  const fetchSessionById = async (sessionId) => {
    try {
      /// timeZoneName  of the browser in this formate America/Indiana/Marengo

      let timeZone;
      if (userData !== null && userData !== undefined) {
        timeZone = userData?.data?.timeZone?.timeZoneName;
      
      } else {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
       
      }
     console.log("user?._id----",user?._id);  
      const response = await getSessionById(sessionId, timeZone, user?._id);
      console.log("aaaaaaaaaaaaaaaresponse",response);
       
      setSessionData(response.data.data);
    } catch (error) {
      console.error("error", error);
    }
  };

  const [iscode, setIscode] = useState(false);
  useEffect(() => {
    const params1 = new Proxy(
      new URLSearchParams(window.location.search),
      {
        get: (searchParams, prop) => searchParams.get(prop),
      },
      []
    );
    let value = params1.code;

    if (value !== null) {
      setIscode(true);
      const code = value;
     
      loadData(code);
    }
    //handleClick();
  }, []);

  const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirect_url = process.env.REACT_APP_REDIRECT_URL;

  const handleClick = useCallback(() => {
    // Get redirectUrl from localStorage
    const redirectUrl = `${redirect_url}/groupsessiondetails/${sessionId}`;
    // Clear local storage
    localStorage.clear();
    // Set redirectUrl in localStorage
    localStorage.setItem("redirectUrl", redirectUrl);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${id}&redirect_uri=${redirect_url}&state=123456&scope=openid,profile,email`;
    window.location.href = url;
  }, [id, redirect_url]);

  const loadData = async (code) => {
 
    try {
      const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
      const redirect_url = process.env.REACT_APP_REDIRECT_URL;
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",

            //   'Authorization': 'Bearer ' + this.state.AccessToken
          },
        }
      );
      const res = await response.json();
      const authorize = res;
      let issuccess = res.success;
      let verified = false;

      if (!issuccess) {
      
        toast.error(res.message);
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);
      } else {
        issuccess = true;
        verified = res.data.data.isVerified;
      }

      if (verified) {
        let allData = JSON.stringify(authorize.data);
        localStorage.setItem("userData", allData);
        const isSessionpage = localStorage.getItem("isSessionpage");
        if (!authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        } else if (isSessionpage === "yes") {
          window.location.replace(`${redirect_url}/session`);
          localStorage.removeItem("isSessionpage");
        } else {
          //get redirectUrl from localStorage
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (!redirectUrl || redirectUrl === "null") {
            window.location.replace(`${redirect_url}/home-page`);
          } else {
            //navigate to redirectUrl
            window.location.href = redirectUrl;
          }
        }
      } else if (issuccess) {
        if (authorize.data.data.completedStep == 1) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          window.location.replace(`${redirect_url}/email`);
          //navigate("/email");
        } else if (authorize.data.data.completedStep == 2) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/address");
        } else if (authorize.data.data.completedStep == 3) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/employement");
        } else if (
          authorize.data.data.completedStep > 3 &&
          !authorize.data.data.hasUserAddedSkills
        ) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        } else {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/email");
        }
      } else {
        navigate("/");
      }
    } catch (e) {
      console.log(e);
    }
  };

  //// share group session link

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const handleOpenShareModal = (id) => {
    setIsShareModalOpen(true);
  };
  const htmltagtoString = (html) => {
    var tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  const sessionDetails = htmltagtoString(sessionData?.topicDetails);

    
     

  return (
    <>
      {/* <SEO
        title={sessionData?.topicName}
        description={sessionData?.topicDetails}
        keywords={sessionData?.topicCoverages}
        image={`${API_Base_Url}/${sessionData?.coverImage}`}
      /> */}
      <Helmet>
        <title>{sessionData?.topicName}</title>
        <meta name="title" content={sessionData?.topicName} />
        <meta name="keywords" content={sessionData?.topicCoverages} />
        <meta name="author" content={sessionData?.mentorName} />
        <meta name="robots" content="index, follow" />
        <meta name="description" content={sessionDetails} />
        <meta property="og:title" content={sessionData?.topicName} />
        <meta property="og:description" content={sessionDetails} />
        <meta
          property="og:image"
          content={`${API_Base_Url}/${sessionData?.coverImage}`}
        />
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      {userData !== null && userData !== undefined ? (
        <Header />
      ) : (
        <Navbar handleClick={handleClick} />
      )}
      <GroupDetailHero topicName={sessionData?.topicName} />
      <section className="">
        <div className="container">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="row g-4">
              <div className="col-lg-8 col-md-12 mx-auto">
                <div className="card">
                  <div className="card-body">
                    <div className="row g-4">
                      <div className="mt-4">
                        <h4 className="">{sessionData?.topicName}</h4>
                        <div className=" mt-4 row">
                          <div className="col-md-6">
                            {/*  <img
                              src={`${API_Base_Url}/${sessionData?.coverImage}`}
                              alt="python"
                              className="img-fluid"
                            /> */}

                            <LazyLoadImage
                              src={`${API_Base_Url}/${sessionData?.coverImage}`}
                              alt="python"
                              className="img-fluid"
                              crossorigin="anonymous"
                            />
                          </div>
                          <div className="col-md-6"> </div>
                        </div>
                        <div className="mt-4">
                          <div className="d-flex justify-content-between">
                            <h4 className="mb-3">Details</h4>
                            <a
                              class="nav-link mb-0 p-0"
                              onClick={() =>
                                handleOpenShareModal(sessionData?._id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <i class="bi bi-reply-fill flip-horizontal ps-1"></i>
                              Share
                            </a>
                          </div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: sessionData?.topicDetails,
                            }}
                          ></p>
                        </div>
                      </div>

                      <div className="mt-4">
                        <h4 className="mb-3">Tags </h4>
                        <ul className="list-inline mb-0 d-flex flex-wrap gap-2">
                          {sessionData?.topicCoverages?.map((skill, index) => (
                            <li className="list-inline-item m-0" key={index}>
                              <div className="btn btn-outline-light btn-sm">
                                {skill}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mt-4">
                  <div className="card-body">
                    <div className="row g-4">
                      <div className="mt-5">
                        <h4 className="mb-3 ">Availability</h4>
                        <div className="card">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-9">
                                <div className="row">
                                  <div className="col-6">
                                    {moment.utc(
                                      sessionData?.sessionDetail?.sessionDate
                                    ).format("dddd, DD MMM YYYY")}
                                  </div>
                                  <div className="col-6">
                                    {formattedFromTime} to {formattedToTime} (
                                    {/* {moment
                                      .tz(sessionData?.sessionDetail?.timeZone)
                                      .format("z")} */}
                                    {userData !== null && userData !== undefined
                                      ? userData?.data?.timeZone?.timeZoneName
                                      : Intl.DateTimeFormat().resolvedOptions()
                                          .timeZone}
                                    )
                                  </div>
                                </div>
                                <div className="mt-2 mb-0 row">
                                  <div className="col-6">
                                    {sessionData?.sessionDetail?.sessionType}{" "}
                                    Session - $
                                    {
                                      sessionData?.sessionDetail
                                        ?.amountPerSeatDollar
                                    }{" "}
                                    Per Seat
                                  </div>
                                  <div className="col-6">
                                    {sessionData?.seatsAvailable > 0 ? (
                                      <span className="text-success">
                                        {sessionData?.seatsAvailable} Seats
                                        Available
                                      </span>
                                    ) : (
                                      <span className="text-danger">
                                        Session Full
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <button
                                  className="btn btn-success-soft"
                                  onClick={()=>handleOpenCOnfirmModal(sessionData)}
                                  disabled={
                                    sessionData?.isEnrolled === true
                                      ? true
                                      : false
                                  }
                                >
                                  {sessionData?.isEnrolled === true
                                    ? "Enrolled"
                                    : "Enroll"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row g-4">
                      <div className="mt-2">
                        <h5 className="mb-3 mt-2">Mentor</h5>
                        <div className="d-flex align-items-center">
                          <div className="avatar avatar-lg me-5">
                            {/* <img
                              className="avatar-img rounded-circle"
                              src={`${API_Base_Url}/${sessionData?.mentorImg}`}
                              alt=""
                            /> */}

                            <LazyLoadImage
                              className="avatar-img rounded-circle"
                              src={`${API_Base_Url}/${sessionData?.mentorImg}`}
                              alt=""
                              crossorigin="anonymous"
                            />
                          </div>
                          <div>
                            <div to="user-profile-mentor.html">
                              <h6 className="mb-0">
                                {" "}
                                <b>{sessionData?.mentorName}</b>{" "}
                              </h6>
                            </div>
                            <span>
                              {" "}
                              <small>{sessionData?.mentorHeadline}</small>{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card mt-3">
                  <div className="card-body">
                    <div className="row g-4 ">
                      <ul className="list-unstyled ">
                        <li className="d-flex justify-content-between mb-3 mt-2 row">
                          <strong className="col-6">Total Sesssions</strong>

                          <span className="col-6">
                            {sessionData?.totalSessions}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between mb-3 row">
                          <div className="col-6">
                            <strong>Session Duration</strong>
                          </div>
                          <div className="col-6">
                            <span>
                              {sessionData?.sessionDetail?.sessionDuration} min
                            </span>
                          </div>
                        </li>
                        <li className="d-flex justify-content-between row">
                          <strong className="col-6">Session Amount</strong>
                          <span className="col-6">
                            ${sessionData?.sessionDetail?.amountPerSeatDollar}{" "}
                            Per Seat
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
      <SessionEnrollModal
        isOpen={isEnrollModalOpen}
        handleClose={() => setIsEnrollModalOpen(false)}
        sessionData={sessionData}
      />
      {/* /// confirmation  modal  alert */}

      <Modal
        show={isConfirmModalOpen}
        onHide={() => setIsConfirmModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to enroll in this session?</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => setIsConfirmModalOpen(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={() => handleEnroll()}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>

      {/* share group session link */}
      <ShareGroupSessionModal
        isShareModalOpen={isShareModalOpen}
        setIsShareModalOpen={setIsShareModalOpen}
        sessionId={sessionId}
      />
    </>
  );
};

export default GroupSessionDetails;
