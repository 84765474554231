import React, { useState, useCallback, useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import CommenHero from '../HeroHeading/CommenHero';
import FaqList from './FaqList';
import Faq from "react-faq-component";
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import SEO from '../../../Component/seo'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';


const FaqDetails = () => {

  const navigate = useNavigate();
  const [show, setShow] = useState(false)
  let headings = {
    n1: "Frequently Asked ",
    n2: "Ques",
    n3: "tions",
  }

  const [iscode, setIscode] = useState(false);
  useEffect(() => {
    const params1 = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }, []);
    let value = params1.code;

    if (value !== null) {
      setIscode(true);
      const code = value;
      //console.log(code)
      loadData(code)
    }
    //handleClick();
  }, []);

  const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirect_url = process.env.REACT_APP_REDIRECT_URL;

  const handleClick = useCallback(() => {
    // Get redirectUrl from localStorage
    const redirectUrl = localStorage.getItem("redirectUrl");
    // Clear local storage
    localStorage.clear();
    // Set redirectUrl in localStorage
    localStorage.setItem("redirectUrl", redirectUrl);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${id}&redirect_uri=${redirect_url}&state=123456&scope=openid,profile,email`;
    window.location.href = url;
  }, [id, redirect_url]);

  const loadData = async (code) => {
    //console.log("redirectURL", redirectUrl)
    try {

      const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
      const redirect_url = process.env.REACT_APP_REDIRECT_URL;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",

            //   'Authorization': 'Bearer ' + this.state.AccessToken
          }
        }
      );
      const res = await response.json();
      const authorize = res
      let issuccess = res.success
      let verified = false

      if (!issuccess) {
        console.log(res.message);
        toast.error(res.message)
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);

      }
      else {
        issuccess = true;
        verified = res.data.data.isVerified
      }

      if (verified) {
        let allData = JSON.stringify(authorize.data);
        localStorage.setItem("userData", allData);
        const isSessionpage = localStorage.getItem("isSessionpage")
        if (!authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else if (isSessionpage === "yes") {
          window.location.replace(`${redirect_url}/session`)
          localStorage.removeItem("isSessionpage")
        }
        else {
          //get redirectUrl from localStorage
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (!redirectUrl || redirectUrl === "null") {
            window.location.replace(`${redirect_url}/home-page`)

          }
          else {
            //navigate to redirectUrl
            window.location.href = redirectUrl;
          }
        }




      }
      else if (issuccess) {
        if (authorize.data.data.completedStep == 1) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          window.location.replace(`${redirect_url}/email`)
          //navigate("/email");
        } else if (authorize.data.data.completedStep == 2) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/address");
        } else if (authorize.data.data.completedStep == 3) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/employement");
        }
        else if (authorize.data.data.completedStep > 3 && !authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/email");
        }


      }
      else {
        navigate("/");
      }
    }
    catch (e) {
      console.log(e)
    }

  }

  return (

    <>
      {
        iscode ? <div class="defaultloader">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#92278f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /></div>
          :
          <>
            <SEO
              title='Talentizo FAQs: Quality Mentors, Online Sessions, and Flexible Learning Options'
              description='Find answers to your questions about Talentizo mentor qualifications, session costs, online learning format, refund policy, and available skills/topics. Discover how our platform ensures quality and satisfaction in your learning journey.'
              keywords='Talentizo mentor qualifications,
        Learning session costs,
        Online educational sessions,
        Talentizo refund policy,
        One-on-one learning,
        Group learning sessions,
        Professional skill development,
        Mentor ratings and reviews,
        Talentizo satisfaction guarantee,
        Expert-led training programs,
           '/>
            <Navbar handleClick={handleClick} />
            <CommenHero allName={headings} name2="FAQs" />
            <section className='bg-white'>
              <div className="faq_padd">
                <FaqList />
              </div>
            </section>
            <Footer />
          </>

      }


    </>


  )
}

export default FaqDetails