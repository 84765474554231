import React, { useEffect, useState, useCallback } from 'react'
//import UserNavbar from '../../../userInterface/UserNavbar';
import Header from '../../../Component/Header';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer';
import CommenHero from '../HeroHeading/CommenHero';
import ReactHtmlParser from 'react-html-parser';
//import Navbar from '../Navbar';
import Navbar from '../../Component/Navbar'
import { toast } from 'react-toastify';

import { ThreeDots } from 'react-loader-spinner'
const TermCondition = ({ setMPrice, setSearchData }) => {
  const navigate = useNavigate();
  let headings = {
    n1: "Terms &",
    n2: "Cond",
    n3: "ition",
  }
  let html = `<p align="center"><strong>Terms of Use</strong></p>
  <p><strong>Table of Contents:</strong></p>
<ul>
<li><strong><strong>Introduction</strong></strong></li>
</ul>
<ul>
<li><strong>Obligations</strong></li>
</ul>
<ul>
<li><strong>Rights and Limits</strong></li>
</ul>
<ul>
<li><strong>Disclaimer and Limit of Liability</strong></li>
</ul>
<ul>
<li><strong>Termination</strong></li>
</ul>
<ul>
<li><strong>Governing Law and Dispute Resolution</strong></li>
</ul>
<ul>
<li><strong>General Terms</strong></li>
</ul>
<ul>
<li><strong>Talentizo &ldquo;Dos and Don&rsquo;ts&rdquo;</strong></li>
</ul>
<ul>
<li><strong>Complaints Regarding Content</strong></li>
</ul>
<ul>
<li><strong>Talentizo Satisfaction Guarantee &amp; Refund Policy</strong></li>
</ul>
<ul>
<li><strong>How To Contact Us</strong></li>
</ul>
<p><strong><strong>1. Introduction</strong></strong></p>
<p><strong>1.1 Contract</strong><br />When you use our Services you agree to all of these terms. Your use of our Services is also subject to our Cookie Policy and our Privacy Policy, which covers how we collect, use, share, and store your personal information.<br />You agree that by clicking &ldquo;Join Now&rdquo;, &ldquo;Join Talentizo&rdquo;, &ldquo;Sign Up&rdquo;, &ldquo;Connect with Linkedin&rdquo;, or similar, by registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Talentizo (even if you are using our Services on behalf of a company). If you do not agree to this contract (&ldquo;Contract&rdquo; or &ldquo;Terms of Use&rdquo;), do not click &ldquo;Join Now&rdquo;, &ldquo;Connect with Linkedin&rdquo; (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.</p>
<p><br /><strong>Services</strong><br />This Contract applies to Talentizo.ai (Talentizo) including but not limited to services offered related to video learning or mentoring sessions, chats, messages, communications and other services that state that they are offered under this Contract (&ldquo;Services&rdquo;), including the offsite collection of data for those Services. Registered users of our Services are &ldquo;Members&rdquo; and unregistered users are &ldquo;Visitors&rdquo;.</p>
<p><br /><strong>Talentizo</strong><br />You are entering into this Contract with Talentizo (also referred to as &ldquo;we&rdquo; and &ldquo;us&rdquo;). We use the term &ldquo;Designated Countries&rdquo; to refer to countries in the European Union (EU), European Economic Area (EEA), and Switzerland.<br />If you reside outside of the &ldquo;Designated Countries&rdquo;, you are entering into this Contract with Talentizo (&ldquo;Talentizo&rdquo;) and Talentizo will be the controller of your personal data provided to, or collected by or for, or processed in connection with our Services.<br />This Contract applies to all Members and Visitors.<br />As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to our Cookie Policy and other documents referenced in our Privacy Policy and updates.</p>
<p><br /><strong>1.2 Members and Visitors</strong><br />When you register and join Talentizo.ai and its Services, you become a Member. If you have chosen not to register for our Services, you may access certain features as a &ldquo;Visitor.&rdquo;</p>
<p><br /><strong>1.3 Change</strong><br />We may make changes to the Contract.<br />We may modify this Contract, our Privacy Policy and our Cookies Policy from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. We agree that changes cannot be retroactive. If you object to any changes, you may close your account. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms as of their effective date.&nbsp;</p>
<p><strong><strong>2. Obligations</strong></strong></p>


<p><strong>2.1 Service Eligibility</strong><br />Here are some promises that you make to us in this Contract:<br />You&rsquo;re eligible to enter into this Contract and you are at least our &ldquo;Minimum Age.&rdquo;<br />The Services are not for use by anyone under the age of 16.<br />To use the Services, you agree that: (1) you must be the "Minimum Age"(described below) or older; (2) you will only have one Talentizo account, which must be in your real name; and (3) you are not already restricted by Talentizo from using the Services. Creating an account with false information is a violation of our terms, including accounts registered on behalf of others or persons under the age of 16.<br />&ldquo;Minimum Age&rdquo; means 16 years old. However, if law requires that you must be older in order for Talentizo to lawfully provide the Services to you without parental consent (including using of your personal data) then the Minimum Age is such older age.</p>
<p><br /><strong>2.2 Your Account</strong><br />You will keep your password a secret.<br />You will not share an account with anyone else and will follow our rules and the law.<br />Members are account holders. You agree to: (1) use a strong password and keep it confidential; (2) not transfer any part of your account (e.g., connections) and (3) follow the law and our list of Dos and Don&rsquo;ts. You are responsible for anything that happens through your account unless you close it or report misuse.</p>
<p><br /><strong>2.3 Payment</strong><br />You&rsquo;ll honor your payment obligations and you are okay with us or our payment gateway partners (such as &ldquo;Braintreepayments.com&rdquo;) storing your payment information. You understand that there may be fees and taxes that are added to our prices.</p>
<p><br />Refunds are subject to our refunds policy mentioned in section 10.</p>
<p><br />You agree that:</p>
<ul>
<li>Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates).</li>
<li>We may store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your Services and to use to pay other Services you may buy.</li>
<li>If you purchase a subscription, your payment method automatically will be charged at the start of each subscription period for the fees and taxes applicable to that period. To avoid future charges, cancel before the renewal date.</li>
<li>All of your purchases of Services are subject to Talentizo&rsquo;s refund policy and you agree with that policy mentioned in section 10 of this contract.</li>
<li>We may calculate taxes payable by you based on the billing information that you provide us at the time of purchase.</li>
</ul>

<strong>2.4 Notices and Messages</strong><br />You&rsquo;re okay with us providing notices and messages to you through our websites, apps, and contact information. If your contact information is out of date, you may miss out on important notices.<br />You agree that we will provide notices and messages to you in the following ways: (1) within the Service, or (2) sent to the contact information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information up to date.<br /><br /><strong>2.5 Sharing</strong><br />When you share information on our Services, others can see, copy and use that information.

<p><br />Our Services allow messaging and sharing of information in many ways, such as your profile, posts, links to news articles, messages, and video calls or chats. Information and content that you share or post may be seen by other Members, Visitors or others (including off of the Services). Where we have made settings available, we will honor the choices you make about who can see content or information.</p>
<p><br />We are not obligated to publish any information or content on our Service and can remove it with or without notice.</p>

<p><strong><strong>3. Rights and Limits</strong></strong></p>

<p><strong>3.1. Your License to Talentizo</strong><br />You own all of the content, feedback and personal information you provide to us, but you also grant us a non-exclusive license to it.</p>
<p>We&rsquo;ll honor the choices you make about who gets to see your information and content, including how it can be used for ads.</p>
<p><br />As between you and Talentizo, you own the content and information that you submit or post to the Services, and you are only granting Talentizo and our affiliates the following non-exclusive license:</p>
<p><br />A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish and process, information and content that you provide through our Services and the services of others, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:</p>
<ul>
<li>You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (a) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.</li>
<li>We will not include your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information, and your social actions may be visible and included with ads, as noted in the Privacy Policy. If you use a Service feature, we may mention that with your name or photo to promote that feature within our Services, subject to your settings.</li>
<li>We will get your consent if we want to give others the right to publish your content beyond the Services. However, if you choose to share your post as "public, everyone or similar", we will enable a feature that allows other Members to embed that public post onto third-party services, and we enable search engines to make that public content findable through their services.</li>
<li>While we may edit and make format changes to your content (such as translating or transcribing it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.</li>
<li>Because you own your content and information and we only have non-exclusive rights to it, you may choose to make it available to others.</li>
</ul>
<p>You and Talentizo agree that if content includes personal data, it is subject to our Privacy Policy.</p>
<p><br />You and Talentizo agree that we may access, store, process and use any information and personal data that you provide in accordance with the terms of the Privacy Policy.</p>
<p><br />By submitting suggestions or other feedback regarding our Services to Talentizo, you agree that Talentizo can use and share (but does not have to) such feedback for any purpose without compensation to you.</p>
<p><br />You promise to only provide information and content that you have the right to share, and that your Talentizo profile will be truthful.<br />You agree to only provide content or information that does not violate the law nor anyone&rsquo;s rights (including intellectual property rights). You also agree that your profile information will be truthful. Talentizo may be required by law to remove certain information or content in certain countries.</p>
<p><br /><strong>3.2 Service Availability</strong><br />We may change or end any Service or modify our prices prospectively.<br />We may change, suspend or discontinue any of our Services. We may also modify our prices effective prospectively upon reasonable notice to the extent allowed under the law.</p>
<p><br />We don&rsquo;t promise to store or keep showing any information and content that you&rsquo;ve posted. Talentizo is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.</p>
<p><br /><strong>3.3 Other Content, Sites and Apps</strong><br />Your use of others&rsquo; content and information posted on our Services, is at your own risk.</p>
<p><br />Others may offer their own products and services through our Services, and we aren&rsquo;t responsible for those third-party activities.</p>
<p><br />By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Talentizo generally does not review content provided by our Members or others. You agree that we are not responsible for others&rsquo; (including other Members&rsquo;) content or information. We cannot always prevent this misuse of our Services, and you agree that we are not responsible for any such misuse. You also acknowledge the risk that you or your organization may be mistakenly associated with content about others when we let connections and followers know you or your organization were mentioned in the news.</p>
<p><br />Talentizo may help connect Members offering their services (mentoring, coaching on skills and general topics, etc.) with Members seeking services (learning on skills and general topics). Talentizo does not perform nor employs individuals to perform these services. You must be at least 18 years of age to offer, perform or procure these services. You acknowledge that Talentizo does not supervise, direct, control or monitor Members in the performance of these services and agree that (1) Talentizo is not responsible for the offering, performance or procurement of these services, (2) Talentizo does not endorse any particular Member&rsquo;s offered services, and (3) nothing shall create an employment, agency, or joint venture relationship between Talentizo and any Member offering services. If you are a Member offering services, you represent and warrant that you have all the required licenses to provide services.<br />Similarly, Talentizo may help you register for and/or attend events organized by Members and connect with other Members who are attendees at such events. You agree that (1) Talentizo is not responsible for the conduct of any of the Members or other attendees at such events, (2) Talentizo does not endorse any particular event listed on our Services, (3) Talentizo does not review and/or vet any of these events, and (4) that you will adhere to these terms and conditions that apply to such events.</p>
<p><br /><strong>3.4 Limits</strong><br />We have the right to limit how you connect and interact on our Services.<br />Talentizo reserves the right to limit your use of the Services, including the number of your connections and your ability to contact other Members. Talentizo reserves the right to restrict, suspend, or terminate your account if you breach this Contract or the law or are misusing the Services (e.g., violating any of the Dos and Don&rsquo;ts).</p>
<p><br /><strong>3.5 Intellectual Property Rights</strong><br />We&rsquo;re providing you notice about our intellectual property rights.<br />Talentizo reserves all of its intellectual property rights in the Services. Trademarks and logos used in connection with the Services are the trademarks of their respective owners. Talentizo logo and other Talentizo trademarks, service marks, graphics and logos used for our Services are trademarks or registered trademarks of Talentizo.</p>
<p><br /><strong>3.6 Automated Processing</strong><br />We use data and information about you to make relevant suggestions to you and others.</p>
<p><br />We use the information and data that you provide and that we have about Members to make recommendations for connections, content and features that may be useful to you. For example, we use data and information about you to recommend professionals who can mentor you or who you can mentor. Keeping your profile accurate and up to date helps us to make these recommendations more accurate and relevant.</p>

<p><strong><strong>4. Disclaimer and Limit of Liability</strong></strong></p>
<p><strong>4.1 No Warranty</strong><br />This is our disclaimer of legal liability for the quality, safety, or reliability of our Services.<br />Talentizo AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, Talentizo AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
<p><br /><strong>4.2 Exclusion of Liability</strong><br />These are the limits of legal liability we may have to you.</p>
<p><br />TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS Talentizo HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), Talentizo, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.<br />Talentizo AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS THE TOTAL FEES PAID OR PAYABLE BY YOU TO Talentizo FOR THE SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY.</p>
<p><br /><strong>4.3 Basis of the Bargain; Exclusions</strong><br />The limitations of liability in this Section 4 are part of the basis of the bargain between you and Talentizo and shall apply to all claims of liability (e.g., warranty, tort, negligence, contract and law) even if Talentizo or its affiliates has been told of the possibility of any such damage, and even if these remedies fail their essential purpose.<br />These limitations of liability do not apply to liability for death or personal injury or for fraud, gross negligence or intentional misconduct, or in cases of negligence where a material obligation has been breached, a material obligation being such which forms a prerequisite to our delivery of services and on which you may reasonably rely, but only to the extent that the damages were directly caused by the breach and were foreseeable upon conclusion of this Contract and to the extent that they are typical in the context of this Contract.&nbsp;</p>

<p><strong><strong>5. Termination</strong></strong></p>
<p>We can each end this Contract, but some rights and obligations survive.<br />Both you and Talentizo may terminate this Contract at any time with notice to the other. On termination, you lose the right to access or use the Services. The following shall survive termination:</p>
<ul>
<li>Our rights to use and disclose your feedback, rating, reviews to us or to Members</li>
<li>Members and/or Visitors&rsquo; rights to further re-share content and information you shared through the Services;</li>
<li>Sections 4, 6, 7, and 8.2 of this Contract;</li>
<li>Any amounts owed by either party prior to termination remain owed after termination.</li>
</ul>

<p><strong><strong>6. Governing Law and Dispute Resolution</strong></strong></p>
<p>In the unlikely event we end up in a legal dispute, you and Talentizo agree to resolve it in Delaware courts using Delaware law.</p>
<p><br />For all users of services, including those who live outside of the United States: You and Talentizo agree that the laws of the State of Delaware, U.S.A., excluding its conflict of laws rules, shall exclusively govern any dispute relating to this Contract and/or the Services.&nbsp;</p>
<p>You and Talentizo both agree that all claims and disputes can be litigated only in the federal or state courts in Delaware, USA, and you and Talentizo each agree to personal jurisdiction in those courts.</p>

<p><strong><strong>7. General Terms</strong></strong></p>
<p><strong>Here are some important details about the Contract.</strong><br />If a court with authority over this Contract finds any part of it unenforceable, you and we agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce the rest of this Contract.</p>
<p><br />This Contract (including additional terms that may be provided by us when you engage with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.</p>
<p><br />If we don't act to enforce a breach of this Contract, that does not mean that Talentizo has waived its right to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of Services) to anyone without our consent.&nbsp;</p>
<p>However, you agree that Talentizo may assign this Contract to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this Contract.</p>
<p><br />You agree that the only way to provide us legal notice is at the addresses provided in Section 10.&nbsp;</p>

<p><strong><strong>8. Talentizo &ldquo;Dos and Don&rsquo;ts&rdquo;</strong></strong></p>
<p>Talentizo is a community of professionals. This list of &ldquo;Dos and Don&rsquo;ts&rdquo; along with our Professional Community Policies limit what you can and cannot do on our Services.<br /><strong>8.1. Dos</strong><br />You agree that you will:</p>
<ul>
<li>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;</li>
<li>Provide accurate information to us and keep it updated;</li>
<li>Use your real name on your profile; and</li>
<li>Use the Services in a professional manner.</li>
</ul>
<p><strong>8.2. Don&rsquo;ts</strong><br />You agree that you will <em>not</em>:</p>
<ul>
<li>Create a false identity on Talentizo, misrepresent your identity, create a Member profile for anyone other than yourself (a real person), or use or attempt to use another&rsquo;s account;</li>
<li>Develop, support or use software, devices, scripts, robots or any other means or processes (including crawlers, browser plugins and add-ons or any other technology) to scrape the Services or otherwise copy profiles and other data from the Services;</li>
<li>Override any security feature or bypass or circumvent any access controls or use limits of the Service (such as caps on keyword searches or profile views);</li>
<li>Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of Talentizo;</li>
<li>Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer));</li>
<li>Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license;</li>
<li>Violate the intellectual property or other rights of Talentizo, including, without limitation, (i) recording, copying or distributing our learning videos or other materials or (ii) copying or distributing our technology, unless it is released under open source licenses; (iii) using the word &ldquo;Talentizo&rdquo; or our logos in any business name, email, or URL;</li>
<li>Post anything that contains software viruses, worms, or any other harmful code;</li>
<li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;</li>
<li>Imply or state that you are affiliated with or endorsed by Talentizo without our express consent (e.g., representing yourself as an accredited Talentizo mentor or trainer);</li>
<li>Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or access to the same, without Talentizo&rsquo;s consent;</li>
<li>Deep-link to our Services for any purpose other than to promote your profile or a Group on our Services, without Talentizo&rsquo;s consent;</li>
<li>Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages;</li>
<li>Monitor the Services&rsquo; availability, performance or functionality for any competitive purpose;</li>
<li>Engage in &ldquo;framing,&rdquo; &ldquo;mirroring,&rdquo; or otherwise simulating the appearance or function of the Services;</li>
<li>Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services);</li>
<li>Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms); and/or</li>
<li>Violate terms concerning a specific Service that are provided when you sign up for or start using such Service, where applicable.</li>
</ul>

<p><strong><strong>9. Complaints Regarding Content</strong></strong></p>
<p>We respect the intellectual property rights of others. We require that information posted by Members be accurate and not in violation of the intellectual property rights or other rights of third parties.</p>
<p><br />For any complaints regarding the content, please reach out to us through the contact information provided in section 11.</p>

<p><strong><strong>10. Talentizo Satisfaction Guarantee &amp; Refund Policy</strong> </strong><br />This policy applies to Talentizo members who choose to take learning sessions with other members enrolled as mentors on Talentizo.</p>
<p>It is our aim to promote happy learning on Talentizo. All sessions on Talentizo are backed by our <strong>Satisfaction Guarantee</strong> to allow you to receive a refund in any of the below scenarios.</p>
<ul>
<li>Full refund issued to you, if you cancel or withdraw at least 24 hours in advance of your session&rsquo;s start time. But, if you cancel or withdraw less than 24 hours in advance of your session&rsquo;s start time, you will not be entitled to any refund.</li>
<li>Full refund issued to you, if the mentor cancels the session in advance of session&rsquo;s meeting time.</li>
<li>You can request for a full refund if the mentor misses a session and does not show up.</li>
</ul>
<p><strong>Additional Policies</strong></p>
<ul>
<li>If you miss a session, you are not entitled to a refund.</li>
<li>If you have not joined the session 10 minutes after the session&rsquo;s start time, the session may be canceled, at the mentor&rsquo;s discretion. This is considered a missed session and you are not entitled to a refund.</li>
<li>Donations made to Talentizo are non-refundable</li>
<li>Refunds can only be issued to the original form of payment used for your purchase on Talentizo, and they cannot be issued to a different card. In the case that the original card is closed or no longer active for any reason, you may need to contact your bank or credit card company to retrieve the funds. In select cases, you may not be able to retrieve your refund, but we will offer you a gift card or apply a credit to your Talentizo account instead.</li>
</ul>

<p><strong><strong>11. How To Contact Us</strong></strong></p>
<p>Our Contact information. Our Help Center also provides information about our Services.<br />For general inquiries, you may contact us at support@Talentizo.ai. For legal notices, you may write to us at 52 Pickwick Dr N, Syosset, NY 11791.</p>
 `
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [iscode, setIscode] = useState(false);
  useEffect(() => {
    const params1 = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }, []);
    let value = params1.code;

    if (value !== null) {
      setIscode(true);
      const code = value;
      //console.log(code)
      loadData(code)
    }
    //handleClick();
  }, []);

  const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirect_url = process.env.REACT_APP_REDIRECT_URL;

  const handleClick = useCallback(() => {
    // Get redirectUrl from localStorage
    const redirectUrl = localStorage.getItem("redirectUrl");
    // Clear local storage
    localStorage.clear();
    // Set redirectUrl in localStorage
    localStorage.setItem("redirectUrl", redirectUrl);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${id}&redirect_uri=${redirect_url}&state=123456&scope=openid,profile,email`;
    window.location.href = url;
  }, [id, redirect_url]);

  const loadData = async (code) => {
    //console.log("redirectURL", redirectUrl)
    try {

      const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
      const redirect_url = process.env.REACT_APP_REDIRECT_URL;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",

            //   'Authorization': 'Bearer ' + this.state.AccessToken
          }
        }
      );
      const res = await response.json();
      const authorize = res
      let issuccess = res.success
      let verified = false

      if (!issuccess) {
        console.log(res.message);
        toast.error(res.message)
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);

      }
      else {
        issuccess = true;
        verified = res.data.data.isVerified
      }

      if (verified) {
        let allData = JSON.stringify(authorize.data);
        localStorage.setItem("userData", allData);
        const isSessionpage = localStorage.getItem("isSessionpage")
        if (!authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else if (isSessionpage === "yes") {
          window.location.replace(`${redirect_url}/session`)
          localStorage.removeItem("isSessionpage")
        }
        else {
          //get redirectUrl from localStorage
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (!redirectUrl || redirectUrl === "null") {
            window.location.replace(`${redirect_url}/home-page`)

          }
          else {
            //navigate to redirectUrl
            window.location.href = redirectUrl;
          }
        }




      }
      else if (issuccess) {
        if (authorize.data.data.completedStep == 1) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          window.location.replace(`${redirect_url}/email`)
          //navigate("/email");
        } else if (authorize.data.data.completedStep == 2) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/address");
        } else if (authorize.data.data.completedStep == 3) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/employement");
        }
        else if (authorize.data.data.completedStep > 3 && !authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/email");
        }


      }
      else {
        navigate("/");
      }
    }
    catch (e) {
      console.log(e)
    }

  }

  let userAllDAta = JSON.parse(localStorage.getItem("userData"));
  return (
    <>
      {
        iscode ? <div class="defaultloader">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#92278f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /></div> :
          <>
            {userAllDAta && userAllDAta.token ?
              <Header />
              : <Navbar handleClick={handleClick} />}
            <CommenHero allName={headings} name2="Terms & Condition" />
            <section>
              <div class="privacy_padd">
                <div>{ReactHtmlParser(html)}</div>
              </div>
            </section>
            <Footer />
          </>

      }


    </>
  )
}
export default TermCondition