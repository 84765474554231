import React, { useEffect, useState,useCallback } from 'react'
//import UserNavbar from '../../../userInterface/UserNavbar';
import { userProfile } from "../../../Services/UserAuth";
import Header from '../../../Component/Header';
import Navbar from '../Navbar';
import Footer from '../Footer';
import CommenHero from '../HeroHeading/CommenHero';
import ReactHtmlParser from 'react-html-parser';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ThreeDots } from 'react-loader-spinner'
const Cookiepolicy = ({ setMPrice, setSearchData }) => {
  const navigate = useNavigate();
  let headings = {
    n1: "Cookie",
    n2: "Pol",
    n3: "icy",
  }

  let html = `<p align="center"><strong>Cookie Policy</strong></p>
          <p>&nbsp;</p>
          <p>By continuing to visit or use our Services, you are agreeing to the use of cookies and similar technologies for the purposes described in this policy.</p>
          <p><strong>What technologies are used?</strong></p>
          <p>This application is built on MERN Stack.</p>
          <table style="width: 100%;" border="0" cellpadding="0">
          <thead>
          <tr>
          <td nowrap="nowrap">
          <p><strong>Type of technology</strong></p>
          </td>
          <td nowrap="nowrap">
          <p><strong>Description</strong></p>
          </td>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>
          <p>Cookies</p>
          </td>
          <td>
          <p>A cookie is a small file placed onto your device that enables Talentizo features and functionality. Any browser visiting our sites may receive cookies from us or cookies from third parties such as our customers, partners or service providers. We or third parties may also place cookies in your browser when you visit non-Talentizo sites that display ads or that host our plugins or&nbsp;<a>tags</a>.</p>
          <p>&nbsp;</p>
          <p>We use two types of cookies: persistent cookies and session cookies. A persistent cookie lasts beyond the current session and is used for many purposes, such as recognizing you as an existing user, so it&rsquo;s easier to return to Talentizo and interact with our Services without signing in again. Since a persistent cookie stays in your browser, it will be read by Talentizo when you return to one of our sites or visit a third party site that uses our Services. Session cookies last only as long as the session (usually the current visit to a website or a browser session).</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Pixels</p>
          </td>
          <td>
          <p>A pixel is a tiny image that may be embedded within web pages and emails, requiring a call (which provides device and visit information) to our servers in order for the pixel to be rendered in those web pages and emails. We use pixels to learn more about your interactions with email content or web content, such as whether you interacted with ads or posts. Pixels can also enable us and third parties to place cookies on your browser.</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Local storage</p>
          </td>
          <td>
          <p>Local storage enables a website or application to store information locally on your device(s). Local storage may be used to improve the Talentizo experience, for example, by enabling features, remembering your preferences and speeding up site functionality.</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Other similar technologies</p>
          </td>
          <td>
          <p>We also use other tracking technologies, such as mobile advertising IDs and tags for similar purposes as described in this Cookie Policy.&nbsp;References to similar technologies in this policy includes pixels, local storage, and other tracking technologies.</p>
          </td>
          </tr>
          </tbody>
          </table>
          <table style="width: 100%;" border="0" cellpadding="0" align="left">
          <thead>
          <tr>
          <td nowrap="nowrap">
          <p><strong>Purpose</strong></p>
          </td>
          <td nowrap="nowrap">
          <p><strong>Description</strong></p>
          </td>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>
          <p>Authentication</p>
          </td>
          <td>
          <p>We use cookies and similar technologies to recognize you when you visit our Services.</p>
          <p>If you&rsquo;re signed into Talentizo, these technologies help us show you the right information and personalize your experience in line with your settings. For example, cookies enable Talentizo to identify you and verify your account.</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Security</p>
          </td>
          <td>
          <p>We use cookies and similar technologies to make your interactions with our Services faster and more secure.</p>
          <p>For example, we use cookies to enable and support our security features, keep your account safe and to help us detect malicious activity and violations of our Terms of Use.</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Preferences, features and services</p>
          </td>
          <td>
          <p>We use cookies and similar technologies to enable the functionality of our Services, such as helping you to fill out forms on our Services more easily and providing you with features, insights and customized content in conjunction with our plugins. We also use these technologies to remember information about your browser and your preferences.</p>
          <p>For example, cookies can tell us which language you prefer and what your communications preferences are. We may also use local storage to speed up site functionality.</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Customized content</p>
          </td>
          <td>
          <p>We use cookies and similar technologies to customize your experience on our Services.</p>
          <p>&nbsp;</p>
          <p>For example, we may use cookies to remember previous searches so that when you return to our services, we can offer additional information that relates to your previous search.</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Plugins on and off Talentizo</p>
          </td>
          <td>
          <p>We use cookies and similar technologies to enable Talentizo plugins both on and off the Talentizo sites.</p>
          <p>For example, our plugins, including the "Apply with Talentizo" button or the "Share" button may be found on Talentizo or third-party sites, such as the sites of our customers and partners. Our plugins use cookies and other technologies to provide analytics and recognize you on Talentizo and third-party sites. If you interact with a plugin (for instance, by clicking "Apply"), the plugin will use cookies to identify you and initiate your request to apply.</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Advertising</p>
          </td>
          <td>
          <p>Cookies and similar technologies help us show relevant advertising to you more effectively, both on and off our Services and to measure the performance of such ads. We use these technologies to learn whether content has been shown to you or whether someone who was presented with an ad later came back and took an action (e.g., downloaded a white paper or made a purchase) on another site. Similarly, our partners or service providers may use these technologies to determine whether we've shown an ad or a post and how it performed or provide us with information about how you interact with ads.</p>
          <p>We may also work with our customers and partners to show you an ad on or off Talentizo, such as after you&rsquo;ve visited a customer&rsquo;s or partner&rsquo;s site or application. These technologies help us provide aggregated information to our customers and partners.</p>
          <p>For further information regarding the use of cookies for advertising purposes, please see&nbsp;<a>Sections 1.4&nbsp;and&nbsp;2.4&nbsp;of the Privacy Policy</a>.</p>
          <p>As noted in&nbsp;<a>Section 1.4</a>&nbsp;of our Privacy Policy, outside&nbsp;<a>Designated Countries</a>, we also collect (or rely on others who collect) information about your device where you have not engaged with our Services (e.g., ad ID, IP address, operating system and browser information) so we can provide our Members with relevant ads and better understand their effectiveness.</p>
          <p>For further information, please see&nbsp;<a>Section 1.4 of the Privacy Policy</a>.</p>
          </td>
          </tr>
          <tr>
          <td>
          <p>Analytics and research</p>
          </td>
          <td>
          <p>Cookies and similar technologies help us learn more about how well our Services and plugins perform in different locations.</p>
          <p>We or our service providers use these technologies to understand, improve, and research products, features and services, including as you navigate through our sites or when you access Talentizo from other sites, applications or devices. We or our service providers, use these technologies to determine and measure the performance of ads or posts on and off Talentizo and to learn whether you have interacted with our websites, content or emails and provide analytics based on those interactions.</p>
          <p>We also use these technologies to provide aggregated information to our customers and partners as part of our Services.</p>
          <p>&nbsp;</p>
          <p>If you are a Talentizo member but logged out of your account on a browser, Talentizo may still continue to log your interaction with our Services on that browser until the expiration of the cookie in order to generate usage analytics for our Services. We may share these analytics in aggregate form with our customers.</p>
          </td>
          </tr>
          </tbody>
          </table>
          <p>&nbsp;</p>
          <p><strong>What third parties use these technologies in connection with our Services?</strong></p>
          <p>Third parties such as our customers, partners and service providers may use cookies in connection with our Services.</p>
          <p>For example, third parties may use cookies in their Talentizo pages, and their advertisements on and off Talentizo for their own marketing purposes.</p>
          <p>Third parties may also use cookies in connection with our off-site Services, such as Talentizo ad services. Third parties may use cookies to help us to provide our Services. We may also work with third parties for our own marketing purposes and to enable us to analyze and research our Services.</p>
          <p><strong>Your Choices</strong></p>
          <p>You have choices on how Talentizo uses cookies and similar technologies. Please note that if you limit the ability of Talentizo to set cookies and similar technologies, you may worsen your overall user experience, since it may no longer be personalized to you. It may also stop you from saving customized settings like login information.</p>
          <p><strong>Opt out of targeted advertising</strong></p>
          <p>As described in&nbsp;<a>Section 2.4 of the Privacy Policy</a>, you have choices regarding the personalized ads you may see.</p>
          <p>Some mobile device operating systems such as Android provide the ability to control the use of mobile advertising IDs for ads personalization. You can learn how to use these controls by visiting the manufacturer&rsquo;s website. We do not use iOS mobile advertising IDs for targeted advertising.</p>
          <p><strong>Browser Controls</strong></p>
          <p>Most browsers allow you to control cookies through their settings, which may be adapted to reflect your consent to the use of cookies. Further, most browsers also enable you to review and erase cookies, including Talentizo cookies. To learn more about browser controls, please consult the documentation that your browser manufacturer provides.</p>
          <p><strong>What is Do Not Track (DNT)?</strong></p>
          <p>DNT is a concept that has been promoted by regulatory agencies such as the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing Internet users to control the tracking of their online activities across websites by using browser settings. As such, Talentizo does not generally respond to &ldquo;do not track&rdquo; signals.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
         `

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  let userAllDAta = JSON.parse(localStorage.getItem("userData"));
  const [userData, setUserData] = useState();

  const [iscode, setIscode] = useState(false);
  useEffect(() => {
    const params1 = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }, []);
    let value = params1.code;

    if (value !== null) {
      setIscode(true);
      const code = value;
      //console.log(code)
      loadData(code)
    }
    //handleClick();
  }, []);

  const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirect_url = process.env.REACT_APP_REDIRECT_URL;

  const handleClick = useCallback(() => {
    // Get redirectUrl from localStorage
    const redirectUrl = localStorage.getItem("redirectUrl");
    // Clear local storage
    localStorage.clear();
    // Set redirectUrl in localStorage
    localStorage.setItem("redirectUrl", redirectUrl);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${id}&redirect_uri=${redirect_url}&state=123456&scope=openid,profile,email`;
    window.location.href = url;
  }, [id, redirect_url]);

  const loadData = async (code) => {
    //console.log("redirectURL", redirectUrl)
    try {

      const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
      const redirect_url = process.env.REACT_APP_REDIRECT_URL;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",

            //   'Authorization': 'Bearer ' + this.state.AccessToken
          }
        }
      );
      const res = await response.json();
      const authorize = res
      let issuccess = res.success
      let verified = false

      if (!issuccess) {
        console.log(res.message);
        toast.error(res.message)
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);

      }
      else {
        issuccess = true;
        verified = res.data.data.isVerified
      }

      if (verified) {
        let allData = JSON.stringify(authorize.data);
        localStorage.setItem("userData", allData);
        const isSessionpage = localStorage.getItem("isSessionpage")
        if (!authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else if (isSessionpage === "yes") {
          window.location.replace(`${redirect_url}/session`)
          localStorage.removeItem("isSessionpage")
        }
        else {
          //get redirectUrl from localStorage
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (!redirectUrl || redirectUrl === "null") {
            window.location.replace(`${redirect_url}/home-page`)

          }
          else {
            //navigate to redirectUrl
            window.location.href = redirectUrl;
          }
        }




      }
      else if (issuccess) {
        if (authorize.data.data.completedStep == 1) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          window.location.replace(`${redirect_url}/email`)
          //navigate("/email");
        } else if (authorize.data.data.completedStep == 2) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/address");
        } else if (authorize.data.data.completedStep == 3) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/employement");
        }
        else if (authorize.data.data.completedStep > 3 && !authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/email");
        }


      }
      else {
        navigate("/");
      }
    }
    catch (e) {
      console.log(e)
    }

  }

  return (

    <>
      {
        iscode ? <div class="defaultloader">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#92278f"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          /></div> 
          :
          <>
            {userAllDAta && userAllDAta.token ?
              <Header />
              : <Navbar handleClick={handleClick} />}
            <CommenHero allName={headings} name1="Cookie Policy" />
            <div class="privacy_padd">
              <div>{ReactHtmlParser(html)}</div>
              {
              }
            </div>
            <Footer />

          </>

      }


    </>



  )

}


export default Cookiepolicy