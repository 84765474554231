import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Shimmer } from "react-shimmer";
import { Link } from "react-router-dom";
import MentorApplication from "../MyProfile/Component/MentorApplication";
import ReviewCard from "./ReviewCard";
import PlatformFeedbackForm from "./PlatformFeedbackForm";
//import { selectUser } from '../../redux/slice/UserData'
import useUser from "../../hooks/useUser";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const SideBar = ({ setType }) => {
  // const user = useSelector(selectUser);
  const { user } = useUser();
  const [userData, setUserData] = useState();
  const [userProfileData, setUserProfileData] = useState(user);
  const [token, setToken] = useState();
  const [skillsList, setSkillsList] = useState([]);
  const [certification, setCertification] = useState([]);
  const [name, setName] = useState("");
  const [field, setField] = useState("");
  const [image, setImage] = useState("/assets/images/myimages/no-image.png");
  const [coverImage, setCoverImage] = useState("/assets/images/bg/01.jpg");
  const [post, setPost] = useState(0);
  const [followers, setFollowers] = useState(0);
  const [following, setFollowing] = useState(0);
  const [skills, setSkills] = useState([]);
  const [companyName, setCompanyname] = useState("");
  const [headline, setHeadline] = useState("");
  const [apply, setApply] = useState(false);
  const [mentorApproval, setMentorApproval] = useState("");
  const [feedbackGiven, setFeedbackGiven] = useState(true);

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("userData")));
    setUserProfileData(user);
    setImage(user?.profilePicture || "/assets/images/myimages/no-image.png");
  }, [user]);

  useEffect(() => {
    if (userData) {
      setImage(
        userData.data.profilePicture || "/assets/images/myimages/no-image.png"
      );
      setCoverImage(userData.data.bannerImage || "/assets/images/bg/01.jpg");
      setToken(userData?.token || null);
    }
  }, [userData]);

  useEffect(() => {
    if (userProfileData) {
      setName(userProfileData.fullName);
      setField(userProfileData.currentJobTitle);
      setImage(
        userProfileData?.profilePicture ||
          "/assets/images/myimages/no-image.png"
      );
      setCoverImage(userProfileData?.bannerImage || "/assets/images/bg/01.jpg");
      setPost(userProfileData?.totalPost || 0);
      setFollowers(userProfileData?.totalFollowers || 0);
      setFollowing(userProfileData?.totalFollowings || 0);
      if (userProfileData?.skills != null) {
        var temskill = userProfileData?.skills.filter((x) => x.isTop == true);
        setSkills(temskill);
      }
      setCompanyname(userProfileData?.currentCompnay || "");
      setHeadline(userProfileData?.headline || "");
      setMentorApproval(userProfileData?.mentorApproval);
      setFeedbackGiven(userProfileData?.hasPlatformFeedbackGiven);

      setSkillsList(userProfileData?.skills || []);
      setCertification(userProfileData?.certification || []);

      var tskill = "";
      var tcertification = "";
      if (skillsList.length > 0) {
        skillsList.map((item, i) => {
          if (i == 0) {
            tskill += item.title;
          } else {
            tskill += ", " + item.title;
          }
        });
      }
      if (certification.length > 0) {
        certification.map((item, i) => {
          if (i == 0) {
            tcertification += item.certificateName;
          } else {
            tcertification += ", " + item.certificateName;
          }
        });
      }
    }
  }, [userProfileData]);
  //const [yearlist, setYearList] = useState([])

  let dates = new Date();
  let year = dates.getFullYear();
  let allYear = [];
  for (let i = 50; i > 0; i--) {
    allYear.push(year - i);
  }

  return (
    <>
      <div className="col-lg-3">
        {/* <!-- Advanced filter responsive toggler START --> */}
        <div className="d-flex align-items-center d-lg-none">
          <button
            className="border-0 bg-transparent"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSideNavbar"
            aria-controls="offcanvasSideNavbar"
          >
            <i className="btn btn-primary fw-bold fa-solid fa-sliders-h"></i>
            <span className="h6 mb-0 fw-bold d-lg-none ms-2">My profile</span>
          </button>
        </div>
        {/* <!-- Advanced filter responsive toggler END --> */}

        {/* <!-- Navbar START--> */}
        <nav className="navbar navbar-expand-lg mx-0">
          <div
            className="offcanvas offcanvas-start"
            tabIndex="-1"
            id="offcanvasSideNavbar"
          >
            {/* <!-- Offcanvas header --> */}
            <div className="offcanvas-header">
              <button
                type="button"
                className="btn-close text-reset ms-auto"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            {/* <!-- Offcanvas body --> */}
            {userProfileData ? (
              <div className="offcanvas-body d-block px-2 px-lg-0">
                {/* <!-- Card START --> */}
                <div className="card overflow-hidden">
                  {/* <!-- Cover image --> */}
                 
                  <img
                    src={coverImage}
                    alt="Description of the image"
                    style={{
                      objectFit: "cover", // Ensures the image covers the entire container while maintaining aspect ratio
                      height: "50px", // Sets the height of the image
                      width: "100%", // Ensures the image fills the container width
                    }}
                    crossorigin="anonymous"
                  />

                  {/* <!-- Card body START --> */}

                  <div className="card-body pt-0">
                    <div className="text-center">
                      {/* <!-- Avatar --> */}
                      <div className="avatar avatar-lg mt-n5 mb-3">
                        <Link to="../myprofile">
                          <LazyLoadImage
                            className="avatar-img rounded border border-white border-3"
                            src={
                              user?.profilePicture ||
                              "/assets/images/myimages/no-image.png"
                            }
                            alt=""
                            crossorigin="anonymous"
                          />
                        </Link>
                      </div>
                      {/* <!-- Info --> */}
                      <h5 className="mb-0">
                        {" "}
                        <Link to="../myprofile">{name}</Link>{" "}
                      </h5>
                      {field != null &&
                        field != "" &&
                        (headline == null || headline == "") && (
                          <>
                            <small className="mt-3">
                              <i className="bi bi-briefcase me-1"></i>
                              {field}
                            </small>
                            <br />
                          </>
                        )}
                      {headline != null && headline != "" && (
                        <>
                          <small className="mt-3">{headline.trim()}</small>
                          <br />
                        </>
                      )}
                      {companyName !== null && companyName !== "" && (
                        <>
                          <small className="mt-3">
                            <b>{companyName}</b>
                          </small>
                          <br />
                        </>
                      )}
                      {skills.length > 0 && (
                        <>
                          <small>
                            {/* <img src="/assets/images/crown.svg" alt="" width="13px" /> */}
                            <LazyLoadImage
                              src="/assets/images/crown.svg"
                              alt=""
                              width="13px"
                            />
                          </small>
                        </>
                      )}
                      {skills.length > 0 &&
                        skills.slice(0, 3).map((item, i) => (
                          <>
                            {" "}
                            <small key={i} className="mt-3">
                              {" "}
                              {item.title}
                            </small>
                            {i != skills.slice(0, 3).length - 1 ? ", " : ""}{" "}
                          </>
                        ))}

                      {/* <!-- User stat START --> */}
                      <div className="hstack gap-2 gap-xl-3 justify-content-center mt-3">
                        {/* <!-- User stat item --> */}
                        <div>
                          <a className="text-dark">
                            <h6 className="mb-0">{post}</h6>
                            <small>{post == 1 ? "Post" : "Posts"}</small>
                          </a>
                        </div>
                        {/* <!-- Divider --> */}
                        <div className="vr"></div>
                        {/* <!-- User stat item --> */}
                        <div>
                          <Link
                            to="/connections"
                            state={{ type: "followers" }}
                            onClick={() => {
                              if (setType) {
                                setType("followers");
                              }
                            }}
                            className="text-dark"
                          >
                            <h6 className="mb-0">{followers}</h6>
                            <small>
                              {followers == 1 ? "Follower" : "Followers"}
                            </small>
                          </Link>
                        </div>
                        {/* <!-- Divider --> */}
                        <div className="vr"></div>
                        {/* <!-- User stat item --> */}
                        <div>
                          <Link
                            to="/connections"
                            state={{ type: "followings" }}
                            onClick={() => {
                              if (setType) {
                                setType("followings");
                              }
                            }}
                            className="text-dark"
                          >
                            <h6 className="mb-0">{following}</h6>
                            <small>Following</small>
                          </Link>
                        </div>
                      </div>
                      {/* <!-- User stat END --> */}
                    </div>

                    {/* <!-- Side Nav END --> */}
                  </div>

                  {/* <!-- Card body END --> */}
                  {/* <!-- Card footer --> */}
                </div>
                {/* <!-- Card END --> */}
                {/* <!-- slider START --> */}
                {/* <UpcommingSession /> */}

                {localStorage.getItem("userData") &&
                  JSON.parse(localStorage.getItem("userData")).data.UserType !=
                    "Admin" && (
                    <>
                      {mentorApproval === "NotApplied" && (
                        <div
                          className="card overflow-hidden"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="card-body pt-0">
                            <div className="">
                              <h5 className="mt-3">
                                Share your expertise, become a mentor.{" "}
                              </h5>
                              <p className="mt-3">
                                <small>
                                  Help other professionals learn from you and
                                  start a rewarding side hustle.
                                </small>
                              </p>
                              <p>
                                <a
                                  type="button"
                                  className="btn btn-success-soft mt-2 active"
                                  onClick={(e) => {
                                    window.$("#modalformentor").modal("show");
                                  }}
                                >
                                  Apply
                                </a>
                              </p>
                            </div>

                            {/* <!-- Side Nav END --> */}
                          </div>
                          {/* <!-- Card body END --> */}
                          {/* <!-- Card footer --> */}
                        </div>
                      )}

                      {/* ///============================= my-mentor rating card===============================================// */}
                      <ReviewCard />

                      {!feedbackGiven && (
                        <>
                          <PlatformFeedbackForm data={setFeedbackGiven} />
                        </>
                      )}
                    </>
                  )}

                {/* <!-- Helper link START --> */}
                {/* <ul className="nav small mt-4 justify-content-center lh-1">
                                <li className="nav-item">
                                    <a className="nav-link" href="#">About</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" >Support</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" >Docs </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link">Help </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" >Privacy & Terms</a>
                                </li>

                            </ul> */}
                {/* <!-- Helper link END --> */}
                {/* <!-- Copyright --> */}
                {/* <p className="small text-center mt-1">©2022 <a className="text-body" target="_blank" href="#">
                                Talentizo </a></p> */}
              </div>
            ) : (
              <div className="offcanvas-body d-block px-2 px-lg-0">
                <Shimmer height={320} width={270} />
              </div>
            )}
          </div>
        </nav>
        {/* <!-- Navbar END--> */}
      </div>

      <MentorApplication
        apply={apply}
        setApply={setApply}
        mentorApproval={mentorApproval}
        setMentorApproval={setMentorApproval}
      />
    </>
  );
};
