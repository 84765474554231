import React, { useEffect, useState ,useCallback} from "react";
import { otherProfile, getAvailability, getPrice, otherProfileforAdmin } from "../../Services/UserAuth";
//import UserNavbar from "../UserNavbar";
import Header from "../../Component/Header";
import Certification from "./components/Certification";
import Experiance from "./components/Experiance";
import Language from "./components/Language";
import Myprofile from "./components/Myprofile";
import Reviews from "./components/Reviews";
import RightBooking from "./components/RightBooking";
import Skills from "./components/Skills";
import Education from "./components/Education";
import { MenteesSkill } from "./components/MenteesSkill";
import { useParams ,useNavigate} from "react-router-dom";
import Navbar from "../../Pages/Component/Navbar";
import Loader from "../../Component/Loader";
import useUser from "../../hooks/useUser";
import { toast } from "react-toastify";
import AdminProfileInfo from "../MyProfile/Component/AdminProfileInfo";

function UserProfile({ setSearchData, searchData, setMPrice }) {
  const { userid } = useParams();
  const { user, updateUser } = useUser()
  const [token, setToken] = useState();
  const [userData, setUserData] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [availabilities, setAvailable] = useState(null)
  const [reviews, setReviews] = useState(null)
  const [timeZone, setTimeZone] = useState();
  const [price, setPrice] = useState([])
  const [userProfileData, setUserProfileData] = useState(user);
  const navigate = useNavigate();
 

  useEffect(() => {
    document.title = "Talentizo Profile";
    let userToken = JSON.parse(localStorage.getItem("userData"));
    setToken(userToken?.token);
  }, []);
  useEffect(() => {
    LoadOtherProfile()
    // let userAllDAta = JSON.parse(localStorage.getItem("userData"));
    let linkedinId = user?.linkedinId
    try {
      if (token !== undefined && token !== null && linkedinId != undefined) {
        getPrice(linkedinId).then((res) => {
          if (res != undefined) {
            if (res.success === true) {
              setPrice(res.data)
            }
          }
        }).catch();
      }
    } catch (error) { }
  }, [token,userid]);

  const LoadOtherProfile = () => {
    let userId = userid
    try {
      if (token !== undefined && token !== null) {
        otherProfile(userId, token)
          .then((res) => {
            if (res != undefined) {
              if (res.success === true) {
                setUserData(res);
                setReviews(res.data.reviews)
                setIsLoaded(true);
                let userBookingData = JSON.stringify(res.data);
                localStorage.setItem('userBookingData', userBookingData)
                if (res.data.isMentor) {
                  let userId = userid
                  localStorage.setItem("Mentor_id", userId)
                  try {
                    if (token !== undefined) {
                      getAvailability(userId, token).then((res) => {
                        if (res !== undefined) {
                          if (res.success === true) {
                            setAvailable(res.data.availabilities.days)
                            setTimeZone(res.data.timeZone)
                          }
                        }
                      }).catch();
                    }
                  } catch (error) { }
                }

              }
            }
          })
          .catch();
      }
      else {
        otherProfileforAdmin(userId)
          .then((res) => {
            if (res != undefined) {
              if (res.success === true) {
                setUserData(res);
                setIsLoaded(true);
                let userBookingData = JSON.stringify(res.data);
                localStorage.setItem('userBookingData', userBookingData)
              }
            }
          })
          .catch();
      }
    } catch (error) { }
  }


  /* Connect with LinkedIn Account */
  const [iscode, setIscode] = useState(false);
  useEffect(() => {
    const params1 = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }, []);
    let value = params1.code;

    if (value !== null) {
      setIscode(true);
      const code = value;
      //console.log(code)
      loadData(code)
    }
    //handleClick();
  }, []);

  const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirect_url = process.env.REACT_APP_REDIRECT_URL;

  const handleClick = useCallback(() => {
    // Get redirectUrl from localStorage
    const redirectUrl = localStorage.getItem("redirectUrl");
    // Clear local storage
    localStorage.clear();
    // Set redirectUrl in localStorage
    localStorage.setItem("redirectUrl", redirectUrl);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${id}&redirect_uri=${redirect_url}&state=123456&scope=openid,profile,email`;
    window.location.href = url;
  }, [id, redirect_url]);

  const loadData = async (code) => {
    //console.log("redirectURL", redirectUrl)
    try {

      const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
      const redirect_url = process.env.REACT_APP_REDIRECT_URL;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",

            //   'Authorization': 'Bearer ' + this.state.AccessToken
          }
        }
      );
      const res = await response.json();
      const authorize = res
      let issuccess = res.success
      let verified = false

      if (!issuccess) {
        console.log(res.message);
        toast.error(res.message)
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);

      }
      else {
        issuccess = true;
        verified = res.data.data.isVerified
      }

      if (verified) {
        let allData = JSON.stringify(authorize.data);
        localStorage.setItem("userData", allData);
        const isSessionpage = localStorage.getItem("isSessionpage")
        if (!authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else if (isSessionpage === "yes") {
          window.location.replace(`${redirect_url}/session`)
          localStorage.removeItem("isSessionpage")
        }
        else {
          //get redirectUrl from localStorage
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (!redirectUrl || redirectUrl === "null") {
            window.location.replace(`${redirect_url}/home-page`)

          }
          else {
            //navigate to redirectUrl
            window.location.href = redirectUrl;
          }
        }




      }
      else if (issuccess) {
        if (authorize.data.data.completedStep == 1) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          window.location.replace(`${redirect_url}/email`)
          //navigate("/email");
        } else if (authorize.data.data.completedStep == 2) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/address");
        } else if (authorize.data.data.completedStep == 3) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/employement");
        }
        else if (authorize.data.data.completedStep > 3 && !authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/email");
        }


      }
      else {
        navigate("/");
      }
    }
    catch (e) {
      console.log(e)
    }

  }



  if (isLoaded) {
    return (
      <>
        {token !== null && token !== undefined ?
          <Header />
          : <Navbar handleClick={handleClick} />
        }
        <main className={token ? "" : "mt-4"}>
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-8 vstack gap-4">
                <Myprofile userData={userData} LoadOtherProfile={LoadOtherProfile} />
                {userData.data.menteeTopics !== null && userData.data.menteeTopics.length > 0 &&
                  <MenteesSkill userData={userData} />
                }
                {userData.data.skills !== null && userData.data.skills.length > 0 &&
                  <Skills userData={userData} />
                }
                {userData.data.experience !== null && userData.data.experience.length > 0 &&
                  <Experiance userData={userData} />
                }
                {userData.data.education !== null && userData.data.education.length > 0 &&
                  <Education userData={userData} />
                }
                {userData.data.certification !== null && userData.data.certification.length > 0 &&
                  <Certification userData={userData} />
                }
                {userData.data.language !== null && userData.data.language.length > 0 &&
                  <Language userData={userData} />
                }
                {/* <ConnectProffestionals /> */}
                {/* /// companyInfo //  */}
                {console.log("userDataaaaaaaaaaa",  userData.data)}
                { 
                  userData.data.UserType != "User" &&
                  <AdminProfileInfo userData={userData} />
                }
              </div>
              <div className="col-lg-4">
                <div className="row g-4">
                  {(userData.data.isMentor && userData.data.mentorApproval == "Approved" && availabilities !== null &&
                    userProfileData?._id !== userid) &&
                    <RightBooking availabilities={availabilities} price={price} timeZone={timeZone} reviews={reviews} />
                  }
                  {/* <Reviews userData={userData} /> */}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  } else {
    return (
      <div style={{ height: "100vh" }}>
        <Loader />
      </div>
    )
  }
}

export default UserProfile;