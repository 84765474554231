import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import Hero from "./Component/Home/Hero";
import Navbar from "./Component/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner'
import JoinLernet from "./Component/Home/JoinLernet"
import Footer from "./Component/Footer"
import { toast } from "react-toastify";
import { userProfile } from "../Services/UserAuth";
import GroupSessionCarousel from "../userInterface/UserHome/Components/GroupSessionCarousel";
import { adminLoginToLearnet } from "../Services/homePageService";


function Home() {
  const [iscode, setIscode] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Access the parameters
    const adminToken = urlParams.get('adminToken');
    console.log("aaaaaaaadmintoken", adminToken)
    if(adminToken){
      learnetAdminLogin(adminToken)
    }

  }, [])

  useEffect(() => {
    const params1 = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    }, []);
    let value = params1.code;

    if (value !== null) {
      setIscode(true);
      const code = value;
      //console.log(code)
      loadData(code)
    }
    //handleClick();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userData && userData?.token) {
      navigate("/home-page");
    }
  }, [userData])

  const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
  const redirect_url = process.env.REACT_APP_REDIRECT_URL;

  const handleClick = useCallback(() => {
    // Get redirectUrl from localStorage
    const redirectUrl = localStorage.getItem("redirectUrl");
    // Clear local storage
    localStorage.clear();
    // Set redirectUrl in localStorage
    localStorage.setItem("redirectUrl", redirectUrl);
    const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${id}&redirect_uri=${redirect_url}&state=123456&scope=openid,profile,email`;
    window.location.href = url;
  }, [id, redirect_url]);

  const loadData = async (code) => {
    //console.log("redirectURL", redirectUrl)
    try {

      const id = process.env.REACT_APP_LINKEDIN_CLIENT_ID;
      const redirect_url = process.env.REACT_APP_REDIRECT_URL;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/v1/user/connect-linkedin?code=${code}&redirect_url=${redirect_url}`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",

            //   'Authorization': 'Bearer ' + this.state.AccessToken
          }
        }
      );
      const res = await response.json();
      const authorize = res
      let issuccess = res.success
      let verified = false

      if (!issuccess) {
        console.log(res.message);
        toast.error(res.message)
        setTimeout(() => {
          window.location.href = "/";
        }, 1500);

      }
      else {
        issuccess = true;
        verified = res.data.data.isVerified
      }

      if (verified) {
        let allData = JSON.stringify(authorize.data);
        localStorage.setItem("userData", allData);
        const isSessionpage = localStorage.getItem("isSessionpage")
        if (!authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else if (isSessionpage === "yes") {
          window.location.replace(`${redirect_url}/session`)
          localStorage.removeItem("isSessionpage")
        }
        else {
          //get redirectUrl from localStorage
          const redirectUrl = localStorage.getItem("redirectUrl");
          if (!redirectUrl || redirectUrl === "null") {
            window.location.replace(`${redirect_url}/home-page`)

          }
          else {
            //navigate to redirectUrl
            window.location.href = redirectUrl;
          }
        }




      }
      else if (issuccess) {
        if (authorize.data.data.completedStep == 1) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          window.location.replace(`${redirect_url}/email`)
          //navigate("/email");
        } else if (authorize.data.data.completedStep == 2) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/address");
        } else if (authorize.data.data.completedStep == 3) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/employement");
        }
        else if (authorize.data.data.completedStep > 3 && !authorize.data.data.hasUserAddedSkills) {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/mentee-topics");
        }
        else {
          let allData = JSON.stringify(authorize.data);
          localStorage.setItem("userData", allData);
          navigate("/email");
        }


      }
      else {
        navigate("/");
      }
    }
    catch (e) {
      console.log(e)
    }

  }

  const learnetAdminLogin = async (adminToken) => {
    try {
        const bodyToSend = {
          authToken: adminToken
        }
       const response = await adminLoginToLearnet(bodyToSend)
      if (response && response.data) {
      
        localStorage.clear();
        let allData = JSON.stringify(response.data.data);
        localStorage.setItem("userData", allData);
        window.location.reload();
        navigate("/home-page");
      }
      
    } catch (error) {
      
    }
  }
  return (

    <>

      {
        iscode ?
          <div class="defaultloader">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#92278f"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            /></div>
          :
          <>
            <section class="herobg">
              <Navbar handleClick={handleClick} />
              <Hero handleClick={handleClick} />
            </section>
            <JoinLernet handleClick={handleClick} />
            <div className="comman_padding">
            <GroupSessionCarousel />
            </div>
            <Footer />

          </>
      }   </>

  );
}

export default Home;
